<div class="proposal-details proposal-details--{{type}}">
	<div class="proposal-details__header">
		<div class="proposal-details__header__nav">
			<div class="proposal-details__header__nav__backtrack">
				<button (click)="goBack()"><span class="material-icons">keyboard_arrow_left</span>Back</button>
				<div class="proposal-details__header__nav__backtrack__breadcrumbs">
					<div [ngClass]="{'active':activeBreadcrumb[0], 'complete':activeBreadcrumb[1] || activeBreadcrumb[2] || activeBreadcrumb[3] || activeBreadcrumb[4]}"
						class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb">
						<p>Invitation</p>
					</div>
					<span class="material-icons">keyboard_arrow_right</span>
					<div [ngClass]="{'active':activeBreadcrumb[1], 'complete':activeBreadcrumb[2] || activeBreadcrumb[3] || activeBreadcrumb[4]}"
						class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb">
						<p>Proposal</p>
					</div>
					<span class="material-icons">keyboard_arrow_right</span>
					<div [ngClass]="{'active':activeBreadcrumb[2], 'complete':activeBreadcrumb[3] || activeBreadcrumb[4]}"
						class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb">
						<p>Active</p>
					</div>
					<span class="material-icons">keyboard_arrow_right</span>
					<div [ngClass]="{'active':activeBreadcrumb[3], 'complete':activeBreadcrumb[4]}"
						class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb">
						<p>Pending Review</p>
					</div>
					<span class="material-icons">keyboard_arrow_right</span>
					<div [ngClass]="{'active':activeBreadcrumb[4], 'complete':false}"
						class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb">
						<p>Order Complete</p>
					</div>
				</div>
			</div>
			<div class="proposal-details__header__nav__content">
				<div class="proposal-details__header__nav__content__status">
					<p class="proposal-details__header__nav__content__status__label">Status:</p>
					<ng-container *ngIf="type=='invitation'">
						<p class="status --ready">Ready</p>
					</ng-container>
					<ng-container *ngIf="type=='proposal'">
						<p class="status --pending" *ngIf="data.status=='proposal'">Pending</p>
						<p class="status --bid-won" *ngIf="data.status=='bid-won'">Bid Won</p>
						<p class="status --bid-lost" *ngIf="data.status=='lost-bid'">Lost Bid</p>
					</ng-container>
					<ng-container *ngIf="type=='active'">
						<p class="status --active" *ngIf="data.reviewStatus=='submit'">Active</p>
						<p class="status --pending" *ngIf="data.reviewStatus=='pending'">In Review</p>
					</ng-container>
				</div>
				<div class="proposal-details__header__nav__content__date"
					*ngIf="type=='invitation' || (type=='proposal' && data.status=='proposal')">
					<p class="proposal-details__header__nav__content__date__label">Proposal Due Date</p>
					<p class="proposal-details__header__nav__content__date__content">{{data.dueDate}}</p>
				</div>
			</div>
		</div>
		<div class="proposal-details__header__info">
			<h1>{{data.address}}</h1>
			<div class="proposal-details__header__info__details">
				<div class="proposal-details__header__info__details__item">
					<p class="proposal-details__header__info__details__item__label">Project Nickname</p>
					<p class="proposal-details__header__info__details__item__text">{{projectName}}</p>
				</div>
				<div class="proposal-details__header__info__details__item">
					<p class="proposal-details__header__info__details__item__label">Order ID</p>
					<p class="proposal-details__header__info__details__item__text">{{data.localInformation.orderId}}</p>
				</div>
				<div class="proposal-details__header__info__details__item">
					<p class="proposal-details__header__info__details__item__label">Proposed Turn Time</p>
					<p class="proposal-details__header__info__details__item__text">
						{{data.localInformation.proposedTurnTime}} Days</p>
				</div>
			</div>
		</div>
		<div class="proposal-details__header__tabs">
			<button (click)="toggleTab('rfp-info')" [class.active]="activeTab=='rfp-info'"><span
					class="material-icons">info</span>RFP Info</button>
			<button (click)="toggleTab('messages')" [class.active]="activeTab=='messages'"><span
					class="material-icons">notifications</span>Messages</button>
		</div>
	</div>
	<div class="proposal-details__content">
		<div class="proposal-details__content__rfp" *ngIf="activeTab=='rfp-info'">
			<div class="proposal-details__content__rfp__block">
				<h2>Local Information</h2>
				<div
					class="proposal-details__content__rfp__block__container proposal-details__content__rfp__block__container--has-image">
					<div class="proposal-details__content__rfp__block__image">
						<p class="proposal-details__content__rfp__block__image__label">Site Map</p>
						<img src="{{data.localInformation.image}}" alt="{{data.address}} Site Map" />
					</div>
					<div class="proposal-details__content__rfp__block__info">
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Property Size</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.acreage.toLocaleString()}} Acres</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Parcel Numbers</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.localInformation.parcelNumbers}}</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Land Use</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.localInformation.landUseFull}}</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Table A</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.localInformation.tableA}}</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Item 20</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.localInformation.item20}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="proposal-details__content__rfp__block" *ngIf="data.documents.length">
				<h2>RFP Documents</h2>
				<div class="proposal-details__content__rfp__block__container">
					<div class="proposal-details__content__rfp__block__documents">
						<div class="proposal-details__content__rfp__block__documents__item"
							*ngFor="let document of data.documents">
							<div class="proposal-details__content__rfp__block__documents__item__content">
								<p class="proposal-details__content__rfp__block__documents__item__content__title">
									{{document.title}}</p>
								<p class="proposal-details__content__rfp__block__documents__item__content__description">
									Site Files</p>
							</div>
							<a href="{{document.link}}" download><span class="material-icons">download</span></a>
						</div>
					</div>
				</div>
			</div>
			<div class="proposal-details__content__rfp__block">
				<h2>Order Information</h2>
				<div class="proposal-details__content__rfp__block__container">
					<div
						class="proposal-details__content__rfp__block__info proposal-details__content__rfp__block__info--horizontal">
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Order Description</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.orderInformation.orderDescription}}</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Special Quote
								Requirements</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.orderInformation.specialQuoteRequirements}}</p>
						</div>
						<div class="proposal-details__content__rfp__block__info__item">
							<p class="proposal-details__content__rfp__block__info__item__label">Site Level Requirements
							</p>
							<p class="proposal-details__content__rfp__block__info__item__content">
								{{data.orderInformation.siteLevelRequirements}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="proposal-details__content__messages" *ngIf="activeTab=='messages'">
			<div class="proposal-details__content__messages__conversations">
				<p *ngFor="let conversation of data.conversations" [class.active]="conversation.id==currentConversation"
					(click)="changeConversation(conversation.id)">{{conversation.recipient}}<span
						class="material-icons">keyboard_arrow_right</span></p>
			</div>
			<ng-container *ngFor="let conversation of data.conversations">
				<ng-container *ngIf="conversation.id==currentConversation">
					<div class="proposal-details__content__messages__current">
						<button class="proposal-details__content__messages__current__send"
							(click)="sendMessage()">Send</button>
						<textarea class="proposal-details__content__messages__current__new"
							placeholder="New Message"></textarea>
						<div class="proposal-details__content__messages__current__list">
							<div *ngFor="let message of conversation.messages"
								class="proposal-details__content__messages__current__list__message"
								[class.received]="!message.sender">
								<div class="proposal-details__content__messages__current__list__message__meta">
									<p class="proposal-details__content__messages__current__list__message__meta__date">
										{{message.date}}</p>
									<p
										class="proposal-details__content__messages__current__list__message__meta__sender">
										{{message.sender ? 'You' : conversation.recipient}}</p>
								</div>
								<div class="proposal-details__content__messages__current__list__message__content">
									<p>{{message.message}}</p>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="proposal-details__footer">
		<div class="proposal-details__footer__left">
			<p>Service Type</p>
			<h3>{{data.serviceType}}</h3>
		</div>
		<div class="proposal-details__footer__right">
			<button class="proposal-details__footer__right__documents"
				*ngIf="data.status!=='lost-bid' || type=='active'" (click)="toggleDocumentsModal()">
				<span class="material-icons">upload</span>
				<p>Documents</p>
			</button>
			<div class="proposal-details__footer__right__update-details"
				*ngIf="type=='invitation' || data.status=='proposal'">
				<div class="proposal-details__footer__right__update-details__bid-amount">
					<p>Bid Amount</p>
					<div class="proposal-details__footer__right__update-details__bid-amount__input">
						<input type="text" value="{{data.bidAmount}}" />
						<button><span class="material-icons">edit</span></button>
					</div>
				</div>
				<div class="proposal-details__footer__right__update-details__turn-time">
					<p>Turn Time</p>
					<div class="proposal-details__footer__right__update-details__turn-time__input">
						<input type="text" value="{{data.turnTime}} Days" />
						<button><span class="material-icons">edit</span></button>
					</div>
				</div>
			</div>
			<button class="proposal-details__footer__right__button-dark" *ngIf="type=='invitation'"
				(click)="submitBid()">Submit Bid</button>
			<button class="proposal-details__footer__right__button-dark"
				*ngIf="type=='proposal' && data.status=='proposal'" (click)="saveBid()">Save Bid</button>
			<button class="proposal-details__footer__right__button-light"
				*ngIf="type=='proposal' && data.status=='proposal'" (click)="decline()">Decline</button>
			<div class="proposal-details__footer__right__details"
				*ngIf="type!=='invitation' && !(type=='proposal' && data.status=='proposal')">
				<div class="proposal-details__footer__right__details__bid-amount">
					<p class="proposal-details__footer__right__details__bid-amount__label">Bid Amount</p>
					<p class="proposal-details__footer__right__details__bid-amount__content">{{data.bidAmount}}</p>
				</div>
				<div class="proposal-details__footer__right__details__turn-time">
					<p class="proposal-details__footer__right__details__turn-time__label">Turn Time</p>
					<p class="proposal-details__footer__right__details__turn-time__content">{{data.turnTime}} Days</p>
				</div>
			</div>
			<button class="proposal-details__footer__right__button-won"
				*ngIf="type=='proposal' && data.status=='bid-won'" (click)="signATP()">Sign ATP</button>
			<button class="proposal-details__footer__right__button-dark"
				*ngIf="data.reviewStatus=='submit' && type=='active'" (click)="submitForReview()">Submit for
				Review</button>
			<p class="proposal-details__footer__right__pending" *ngIf="type=='active' && data.reviewStatus=='pending'">
				Pending Review</p>
		</div>
	</div>
</div>
<documents-dialog [active]="documentsActive" [documents]="data.documents" [serviceType]="data.serviceType"
	[address]="data.address" (modalClosed)="toggleDocumentsModal()"
	[ngClass]="{'documents-dialog--active': documentsActive}"></documents-dialog>