<div class="back-btn"><span class="material-icons">keyboard_arrow_left</span> <a routerLink="/login"
    class="back-btn">Return To
    Login</a></div>
<div class="login-container">
  <a routerLink="/"><img class="login-logo" src="../../assets/img/logo.png" alt="logo"></a>
  <div id="Create-Account">
    <form [formGroup]="createAccount">
      <div class="title">Create Account</div>
      <hr />
      <div class="create-form">
        <div class="form-control">
          <label class="form-label">First Name <span class="req">*</span></label>
          <input type="text" class="input" formControlName="firstName">
          <div
            *ngIf="createAccount.controls['firstName']?.invalid && (createAccount.controls['firstName'].dirty || createAccount.controls['firstName'].touched)">
            <div *ngIf="createAccount.controls['firstName'].errors?.['required']" class="error-message">
              First name is required.
            </div>
          </div>
        </div>
        <div class="form-control" style="margin-left: 10px;">
          <label class="form-label">Last Name <span class="req">*</span></label>
          <input type="text" class="input" formControlName="lastName">
          <div
            *ngIf="createAccount.controls['lastName']?.invalid && (createAccount.controls['lastName'].dirty || createAccount.controls['lastName'].touched)">
            <div *ngIf="createAccount.controls['lastName'].errors?.['required']" class="error-message">
              Last name is required.
            </div>
          </div>
        </div>
      </div>
      <div class="create-form">
        <div class="">
          <label class="form-label">Email Address <span class="req">*</span></label>
          <input type="email" class="input" formControlName="email" maxlength="50">
          <div
            *ngIf="createAccount.controls['email']?.invalid && (createAccount.controls['email'].dirty || createAccount.controls['email'].touched)">
            <div class="error-message" *ngIf="createAccount.controls['email'].errors?.['required']">
              Email is required.
            </div>
            <div
              *ngIf="createAccount.controls['email'].value!='' && !createAccount.controls['email'].value?.match(emailRegx)"
              class="error-message">
              Please enter a valid email.
            </div>
          </div>
        </div>
      </div>
      <div class="create-form">
        <div class="form-control1">
          <label class="form-label">Password <span class="req">*</span></label>
          <input type="password" class="input" formControlName="password" style="margin-bottom: -0px;" maxlength="12">
          <div>
            <span class="form-label">
              Password is case sensitive and must be at least 8 characters long.<br>
              At least 1 <b>lowercase</b> letter.<br>
              At least 1 <b>uppercase</b> letter.<br>
              At least 1 <b>numeric</b> character.<br>
              At least 1 <b>special</b> character.
            </span>
          </div>
          <div
            *ngIf="createAccount.controls['password']?.invalid && (createAccount.controls['password'].dirty || createAccount.controls['password'].touched)">
            <div *ngIf="createAccount.controls['password'].errors?.['required']" class="error-message">
              Password is required.
            </div>
            <div class="error-message" *ngIf="createAccount.controls['password']?.dirty">
              <span class="error-message"
                *ngIf="!createAccount.controls['password'].value?.match(passwordRegx)&&!createAccount.controls['password'].errors?.['required']">Password
                does not meet the requirements.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="create-form mt-10">
        <div class="form-control1">
          <label class="form-label">Confirm Password <span class="req">*</span></label>
          <input type="password" class="input" formControlName="confirmPassword" maxlength="12">
          <div
            *ngIf="createAccount.controls['confirmPassword']?.invalid && (createAccount.controls['confirmPassword'].dirty || createAccount.controls['confirmPassword'].touched)">
            <span class="error-message" *ngIf="createAccount.controls.confirmPassword.errors?.['required']">
              Please confirm your password.
            </span>
          </div>
          <span class="error-message"
            *ngIf="createAccount.controls['confirmPassword'].value!=''&&createAccount.controls['confirmPassword'].value!=createAccount.controls['password'].value">
            Passwords do not match.
          </span>
        </div>
      </div>
      <hr />
      <label class="form-label"><span class="req">*</span> Indicates a required field.</label>
      <div class="re-captcha">
        <re-captcha [siteKey]="recaptchaSiteKey" (resolved)="onCaptchaResolved($event)">
        </re-captcha>
      </div>
      <div class="panel-fields">
        <button type="submit" class="btn btn-default w-25" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="register()">Submit</button>
      </div>
    </form>
  </div>
  <div class="footer">
    <hr />
    <div class="sm-text">
      We value your privacy and will not share your information with any other parties. <a
        href="https://www.cretelligent.com/privacy-policy/" target="_blank">Click here</a> to view our privacy
      policy.
    </div>
  </div>
</div>