<div class="back-btn"><span class="material-icons">keyboard_arrow_left</span> <a routerLink="/login"
        class="back-btn">Return To
        Login</a></div>
<div class="login-container">
    <a routerLink="/invitations"><img class="login-logo" src="../../assets/img/logo.png" alt="logo"></a>
    <div class="title">Reset Password</div>
    <hr />
    <form [formGroup]="resetPassword">
        <div id="reset-password">

            <div class="create-form">
                <div class="form-control1">
                    <label class="form-label">Password <span class="req">*</span></label>
                    <input type="password" class="input" formControlName="password" style="margin-bottom: -0px;"
                        maxlength="12">
                    <div>
                        <span class="form-label">
                            Password is case sensitive and must be at least 8 characters long.<br>
                            At least 1 <b>lowercase</b> letter.<br>
                            At least 1 <b>uppercase</b> letter.<br>
                            At least 1 <b>numeric</b> character.<br>
                            At least 1 <b>special</b> character.
                        </span>
                    </div>
                    <div
                        *ngIf="resetPassword.controls['password']?.invalid && (resetPassword.controls['password'].dirty || resetPassword.controls['password'].touched)">
                        <div *ngIf="resetPassword.controls['password'].errors?.['required']" class="error-message">
                            Password is required.
                        </div>
                        <div class="error-message" *ngIf="resetPassword.controls['password']?.dirty">
                            <span class="error-message"
                                *ngIf="!resetPassword.controls['password'].value?.match(passwordRegx)&&!resetPassword.controls['password'].errors?.['required']">Password
                                does not meet the requirements.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="create-form mt-10">
                <div class="form-control1">
                    <label class="form-label">Confirm Password <span class="req">*</span></label>
                    <input type="password" class="input" formControlName="confirmPassword" maxlength="12">
                    <div
                        *ngIf="resetPassword.controls['confirmPassword']?.invalid && (resetPassword.controls['confirmPassword'].dirty || resetPassword.controls['confirmPassword'].touched)">
                        <span class="error-message" *ngIf="resetPassword.controls.confirmPassword.errors?.['required']">
                            Please confirm your password
                        </span>
                    </div>
                    <span class="error-message"
                        *ngIf="resetPassword.controls['confirmPassword'].value!=''&&resetPassword.controls['confirmPassword'].value!=resetPassword.controls['password'].value">
                        Passwords do not match
                    </span>
                </div>
            </div>
            <hr />
            <label class="form-label"><span class="req">*</span> Indicates a required field.</label>
            <div class="panel-fields">

                <button type="submit" class="btn btn-default w-25" (click)="cancel()">
                    Cancel
                </button>
                <button type="submit" class="btn btn-primary" (click)="setPassword()">Submit</button>
            </div>
        </div>
        <div class="footer">
            <hr />
            <div class="sm-text">
                We value your privacy and will not share your information with any other parties. 
                <a href="https://www.cretelligent.com/privacy-policy/" target="-blank">Click here</a> to view our
                privacy
                policy.
            </div>
        </div>
    </form>
</div>