import { Component, inject, model } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sample-user',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './sample-user.component.html',
  styleUrl: './sample-user.component.css',
})
export class SampleUserComponent {
  childSampleUser = model<User>({});
  updatedChildUserName = model<string>('');

  userService: UserService = inject(UserService);

  saveSampleText() {
    if (this.childSampleUser() != null) {
      this.userService
        .saveSampleText(this.updatedChildUserName(), this.childSampleUser())
        .subscribe((updatedUser) => this.childSampleUser.set(updatedUser));
    }
  }
}
