<div class="info-header" [ngClass]="{'expanded': expanded}">
	<div class="info-header__left">
		<button (click)="accordion()" class="info-header__left__button"><span
				class="material-icons">keyboard_arrow_up</span></button>
		<h2 (click)="accordion()">{{ title }}</h2>
	</div>
	<div class="info-header__right">
		<button class="info-header__right__overview" (click)="projectOverview()">Project Overview</button>
		<button *ngIf="type=='invitations'" class="info-header__right__atp" (click)="submitAllBids()"><span
				class="material-icons">gavel</span>Submit All Bids</button>
		<button *ngIf="type=='proposals'" class="info-header__right__atp" (click)="signAllATP()"><span
				class="material-icons">login</span>Sign All ATP</button>
	</div>
</div>