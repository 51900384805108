import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  keepMeSignedIn: boolean = false;
  isValid: boolean = true;
  passwordRegx: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()_+=~`@$!%*?&])[A-Za-z\d#^()_+=~`@$!%*?&]{8,}$/;
  emailRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.appService.getMessage.subscribe((message) => {
      if (message != '') {
        this.toastr.success(message);
        this.appService.setMessage('');
      }
    });
  }

  protected loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegx),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(this.passwordRegx),
    ]),
    keepMeSigned: new FormControl(''),
  });

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      if (
        this.loginForm.value.password != '' &&
        this.loginForm.controls['password'].value?.match(this.passwordRegx) ==
          null
      ) {
        this.isValid = false;
      }
      return;
    }

    if (this.loginForm.valid) {
      if (this.loginForm.value.email && this.loginForm.value.password) {
        this.authService
          .login(this.loginForm.value.email, this.loginForm.value.password)
          .subscribe((data) => {
            localStorage.setItem('user', data.data);
            if (this.keepMeSignedIn) {
              localStorage.setItem('keepMeSignedIn', 'true');
            }
            if (data.status === 'FAILURE') {
              this.toastr.error(data.message);
            }
            if (data.code === 302) {
              this.router.navigate(['resetpassword']);
            } else if (data.code === 200) {
              localStorage.setItem('token', data.token);
              sessionStorage.setItem('token', data.token);
              this.router.navigate(['invitations']);
            }
          });
      }
    }
  }
  navigateRegister() {
    this.router.navigate(['createaccount']);
  }
}
