import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';

@Component({
	standalone: true,
  selector: 'info-table-item',
	imports: [RouterLink, CommonModule, DocumentsDialog],
  templateUrl: './info-table-item.component.html',
	styleUrl: './info-table-item.component.scss',
})

export class InfoTableItem {

	serviceInfoToggle: boolean = false;
	documentsActive: boolean = false;
	editToggle: boolean = false;

	@Input() id: number = 0;
	@Input() status: string = '';
	@Input() site: number = 0;
	@Input() address: string = '';
	@Input() acreage: number = 0;
	@Input() landUse: string = '';
	@Input() serviceType: string = '';
	@Input() tableA: string = '';
	@Input() item20: string = '';
	@Input() dueDate: string = '';
	@Input() documents: any = '';
	@Input() turnTime: string = '';
	@Input() bidAmount: number = 0;
	@Input() reviewStatus: string = '';
	@Input() type: string = '';

	@Input() showStatus: boolean = true;
	@Input() showSite: boolean = true;
	@Input() showAddress: boolean = true;
	@Input() showAcreage: boolean = true;
	@Input() showLandUse: boolean = true;
	@Input() showServiceType: boolean = true;
	@Input() showDueDate: boolean = true;
	@Input() showServiceInfo: boolean = true;
	@Input() showActions: boolean = true;

	addressInfo(){
		alert('addressInfo');
	}

	serviceInfo(){
		this.serviceInfoToggle = !this.serviceInfoToggle;
	}

	toggleDocumentsModal(){
		this.documentsActive = !this.documentsActive;
	}

	declineProposal(){
		alert('declineProposal');
	}

	editProposal(){
		if(this.editToggle){
			alert('saveProposal');
		}
		this.editToggle = !this.editToggle;
	}

	viewProposal(){
		alert('viewProposal');
	}

	submitProposal(){
		alert('submitProposal');
	}

	saveTurnTime(){
		alert('saveTurnTime');
	}

	saveBidAmount(){
		alert('saveBidAmount');
	}
}
