<div class="info-table info-table--{{type}}" [style.grid-template-columns]="gridLayout">
	<ng-container *ngIf="expanded">
		<div class="info-table-header">
			<button *ngIf="showStatus" (click)="statusSort = filterToggle(statusSort, 'status')">Status<span
					class="material-icons">filter_list</span></button>
			<button *ngIf="showSite" (click)="siteSort = filterToggle(siteSort, 'site')">Site<span
					class="material-icons">filter_list</span></button>
			<button *ngIf="showAcreage" (click)="acreageSort = filterToggle(acreageSort, 'acreage')">Acreage<span
					class="material-icons">filter_list</span></button>
			<button *ngIf="showLandUse" (click)="landUseSort = filterToggle(landUseSort, 'landUse')">Land Use<span
					class="material-icons">filter_list</span></button>
			<button *ngIf="showServiceType"
				(click)="serviceTypeSort = filterToggle(serviceTypeSort, 'serviceType')">Service Type<span
					class="material-icons">filter_list</span></button>
			<button *ngIf="showDueDate" (click)="dueDateSort = filterToggle(dueDateSort, 'dueDate')">Due Date<span
					class="material-icons">filter_list</span></button>
			<p></p>
			<button *ngIf="showActions" class="info-table-header__action"
				(click)="actionsSort = filterToggle(actionsSort, 'actions')">Action<span
					class="material-icons">filter_list</span></button>
			<div class="info-table-header__more">
				<button (click)="more()"><span class="material-icons">more_vert</span></button>
				<div *ngIf="moreOpen" class="info-table-header__more__menu">
					<div class="info-table-header__more__menu__item"
						(click)="showStatus = toggleColumn(showStatus); adjustTable()">
						<span *ngIf="showStatus" class="material-icons">check</span>
						<p>Status</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showSite = toggleColumn(showSite); adjustTable()">
						<span *ngIf="showSite" class="material-icons">check</span>
						<p>Location</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showAcreage = toggleColumn(showAcreage); adjustTable()">
						<span *ngIf="showAcreage" class="material-icons">check</span>
						<p>Acreage</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showLandUse = toggleColumn(showLandUse); adjustTable()">
						<span *ngIf="showLandUse" class="material-icons">check</span>
						<p>Land Use</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showServiceType = toggleColumn(showServiceType); adjustTable()">
						<span *ngIf="showServiceType" class="material-icons">check</span>
						<p>Service Type</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showDueDate = toggleColumn(showDueDate); adjustTable()">
						<span *ngIf="showDueDate" class="material-icons">check</span>
						<p>Proposal Due</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showServiceInfo = toggleColumn(showServiceInfo); adjustTable()">
						<span *ngIf="showServiceInfo" class="material-icons">check</span>
						<p>Service Info</p>
					</div>
					<div class="info-table-header__more__menu__item"
						(click)="showActions = toggleColumn(showActions); adjustTable()">
						<span *ngIf="showActions" class="material-icons">check</span>
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
		<info-table-item *ngFor="let item of data; let i = index" [id]="item.id" [status]="item.status"
			[site]="item.site" [address]="item.address" [acreage]="item.acreage" [landUse]="item.landUse"
			[serviceType]="item.serviceType" [tableA]="item.localInformation.tableA"
			[item20]="item.localInformation.item20" [dueDate]="item.dueDate" [documents]="item.documents"
			[turnTime]="item.turnTime" [bidAmount]="item.bidAmount" [reviewStatus]="item.reviewStatus" [type]="type"
			[showStatus]="showStatus" [showSite]="showSite" [showAcreage]="showAcreage" [showLandUse]="showLandUse"
			[showServiceType]="showServiceType" [showDueDate]="showDueDate" [showServiceInfo]="showServiceInfo"
			[showActions]="showActions">
		</info-table-item>
	</ng-container>
</div>