<div class="info-table-item">
	<ng-container *ngIf="showStatus">
		<ng-container *ngIf="type=='proposals'">
			<p class="status --proposal" *ngIf="status=='proposal'">Proposal</p>
			<p class="status --bid-won" *ngIf="status=='bid-won'">Bid Won</p>
			<p class="status --bid-lost" *ngIf="status=='lost-bid'">Lost Bid</p>
		</ng-container>
		<ng-container *ngIf="type=='active'">
			<p class="status --active">Active</p>
		</ng-container>
		<ng-container *ngIf="type=='invitations'">
			<p class="status --invitation">Invitation</p>
		</ng-container>
	</ng-container>
	<div class="address" *ngIf="showSite">
		<p class="address__site">#{{ site }}</p>
		<div class="address__address" (click)="addressInfo()"><span class="material-icons">info</span>
			<p>{{ address }}</p>
		</div>
	</div>
	<p *ngIf="showAcreage">{{ acreage.toLocaleString() }}</p>
	<p *ngIf="showLandUse">{{ landUse.charAt(0).toUpperCase() + landUse.slice(1) }} Building</p>
	<div *ngIf="showServiceType" class="service-type">
		<span class="material-icons" (mouseenter)="serviceInfo()" (mouseleave)="serviceInfo()">info</span>
		<p>{{ serviceType }}</p>
		<div class="service-type__info" [ngClass]="{'active':serviceInfoToggle}">
			<p class="service-type__info__label">Table A</p>
			<p class="service-type__info__content">{{ tableA }}</p>
			<p class="service-type__info__label">Item 20</p>
			<p class="service-type__info__content">{{ item20 }}</p>
		</div>
	</div>
	<p *ngIf="showDueDate">{{ dueDate }}</p>
	<div class="documents" (click)="toggleDocumentsModal()">
		<span class="material-icons">upload</span>
		<div *ngIf="documents.length > 0" class="documents__number">{{ documents.length }}</div>
	</div>
	<documents-dialog [active]="documentsActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
		(modalClosed)="toggleDocumentsModal()"
		[ngClass]="{'documents-dialog--active': documentsActive}"></documents-dialog>
	<ng-container *ngIf="showActions">
		<div *ngIf="type=='proposals' && !editToggle" class="proposals-actions">
			<div class="turn-time">
				<p>Turn Time</p>
				<p>{{ turnTime }} Days</p>
			</div>
			<div class="bid-amount">
				<p>Bid Amount</p>
				<p>{{ bidAmount }}</p>
			</div>
		</div>
		<div *ngIf="type=='invitations' || (type=='proposals' && editToggle)" class="info-table-actions-edit">
			<div class="info-table-actions-edit__item">
				<p class="info-table-actions-edit__item__label">Turn Time</p>
				<div class="info-table-actions-edit__item__input">
					<input type="text" value="{{turnTime}} Days" (keyup.enter)="saveTurnTime()" />
					<button (click)="saveTurnTime()"><span class="material-icons">edit</span></button>
				</div>
			</div>
			<div class="info-table-actions-edit__item">
				<p class="info-table-actions-edit__item__label">Bid Amount</p>
				<div class="info-table-actions-edit__item__input">
					<input type="text" value="{{bidAmount}}" (keyup.enter)="saveBidAmount()" />
					<button (click)="saveBidAmount()"><span class="material-icons">edit</span></button>
				</div>
			</div>
		</div>
		<div *ngIf="type=='active'" class="info-table-review">
			<button *ngIf="reviewStatus=='submit'" (click)="submitProposal()">Submit for Review</button>
			<p *ngIf="reviewStatus=='pending'">Pending Review</p>
		</div>
	</ng-container>
	<ng-container *ngIf="type=='proposals'">
		<div class="info-table-button" (click)="editProposal()" *ngIf="status=='proposal'">
			<span *ngIf="!editToggle" class="material-icons">edit</span>
			<p *ngIf="!editToggle">Edit</p>
			<span *ngIf="editToggle" class="material-icons">save</span>
			<p *ngIf="editToggle">Save</p>
		</div>
		<div *ngIf="status=='lost-bid' || status=='bid-won'"></div>
	</ng-container>
	<div *ngIf="type!='proposals'"></div>
	<div class="info-table-button" [routerLink]="['/', type, id]">
		<span class="material-icons">visibility</span>
		<p>View</p>
	</div>
</div>