{
  "name": "Steve Rogers",
  "groups": [
    {
      "groupId": "66d0a00fd67f18c48adda7f7",
      "groupName": "Taco Bell Holdings",
      "type": "invitation",
      "data": [
        {
          "id": "3c869c50-bb11-4952-9f24-2e90f65ee891",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2988,
          "address": "959 Rapelye Street, Calverton, Maryland, 8315",
          "acreage": 8118,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/03/26 09:55:46 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 24,
          "bidAmount": "$2,313.01",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7114,
            "parcelNumbers": 95509774909666,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "c59394c1-deb8-48af-8168-e281ca4fb62b",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/14/27 07:39:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/24/25 04:21:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/23/28 10:59:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/17/27 09:03:52 AM"
                }
              ]
            },
            {
              "id": "ae9c38a3-6521-4db4-9808-f038b7135263",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/06/24 06:49:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/20/29 10:19:00 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/08/25 02:32:10 AM"
                }
              ]
            },
            {
              "id": "df40521e-9a6d-4d7f-a3b5-4142ca877c4a",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/24/27 04:06:21 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/26/28 04:06:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/06/26 11:30:58 PM"
                }
              ]
            },
            {
              "id": "895344ac-d2c2-416f-b6b0-cfc4f14f5103",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/29/29 11:02:07 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/27/29 03:05:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/19/25 09:39:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/29/27 06:39:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/26/26 08:58:41 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "74b01dc6-3343-4261-be05-5c5e2e37f307",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 1340,
          "address": "410 Goodwin Place, Templeton, Pennsylvania, 4408",
          "acreage": 6443,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "02/23/28 01:37:37 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 4,
          "bidAmount": "$3,716.36",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7802,
            "parcelNumbers": 84938789891491,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "75cbe664-a5eb-48ea-9ae7-5a92685ccdaf",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/02/26 08:09:22 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/27/24 07:09:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/27/26 09:20:59 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/08/28 01:44:14 AM"
                }
              ]
            },
            {
              "id": "021c4b7d-3517-467b-8241-518e6c86d4a3",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/24/27 09:54:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/11/29 05:46:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/28 01:36:33 PM"
                }
              ]
            },
            {
              "id": "db67617e-8670-4384-9f61-48f9a291385b",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/05/26 12:55:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/03/28 09:05:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/21/25 05:34:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/27/26 09:12:28 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/05/27 03:57:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/25/25 07:29:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/19/28 01:51:52 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ada4903c-10bd-41c0-af38-48d1e249ad13",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3904,
          "address": "830 Williams Avenue, Advance, New Mexico, 1183",
          "acreage": 3906,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "11/16/28 07:18:55 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 50,
          "bidAmount": "$3,745.52",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1552,
            "parcelNumbers": 70639524977051,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "181bbca3-a022-4955-89f8-f09db4e52bbb",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/07/28 01:52:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/19/29 03:00:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/30/24 05:40:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/19/27 01:06:50 AM"
                }
              ]
            },
            {
              "id": "ec30de13-5eac-451f-b8ba-fbde03ae7eee",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/10/25 11:07:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/15/27 11:22:11 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/22/26 12:25:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/25/25 09:29:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/28/25 09:22:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/18/25 05:07:18 AM"
                }
              ]
            },
            {
              "id": "7391353c-b448-4446-be39-da988dae94c2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/22/29 08:28:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/26/28 09:52:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/28/29 04:32:59 PM"
                }
              ]
            },
            {
              "id": "15360b04-6ce3-4e74-b1e4-f4fd134421a5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/10/26 07:39:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/24/27 11:53:07 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "fbe247b3-c6f2-42e5-9555-3ed2a041c244",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3874,
          "address": "756 Euclid Avenue, Suitland, Arizona, 7151",
          "acreage": 3433,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/14/24 12:53:19 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 66,
          "bidAmount": "$1,425.26",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6043,
            "parcelNumbers": 73613839007528,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "5b8280d7-432e-4f2b-9ab4-ab3967c32f89",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/10/29 08:20:14 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/20/29 05:47:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/22/27 04:58:46 AM"
                }
              ]
            },
            {
              "id": "944e11dd-8c63-4716-a451-e6aac719aa75",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/02/28 09:45:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/22/25 10:18:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/22/27 02:35:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/09/28 09:07:08 AM"
                }
              ]
            },
            {
              "id": "676f8012-574d-4d71-8c26-8bf16cae3da4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/29 11:26:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/31/27 01:23:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/07/25 01:56:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/23/25 06:27:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/21/24 05:08:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/11/26 04:21:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/27 11:47:02 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "b3a770a8-14cd-42d1-a4c5-3634ef556ba2",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 299,
          "address": "345 Desmond Court, Ola, Texas, 5034",
          "acreage": 1596,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "11/07/26 08:42:22 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 57,
          "bidAmount": "$3,301.98",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8291,
            "parcelNumbers": 83051713585790,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2a238de7-6330-4af6-a06c-8e6b245ee3be",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/01/27 07:08:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/12/28 05:10:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/13/27 01:57:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/22/25 12:39:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/30/27 01:12:08 PM"
                }
              ]
            },
            {
              "id": "16798f68-522a-4acb-bf4f-f7dbeba47717",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/31/29 09:24:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/29 04:03:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/20/25 06:52:22 PM"
                }
              ]
            },
            {
              "id": "b93dd230-34bf-4aba-901d-728b69d00eb5",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/28 07:28:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/26 08:09:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/11/29 01:41:45 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/05/29 01:39:01 AM"
                }
              ]
            },
            {
              "id": "c02a7d28-8923-4a76-b5be-e0d92849dd9f",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/29 12:36:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/19/26 08:17:29 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "4c658b9b-7195-4243-bacb-9cc5b1017d4e",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 667,
          "address": "825 Clifton Place, Celeryville, Michigan, 4287",
          "acreage": 1768,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "01/04/25 05:10:28 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 83,
          "bidAmount": "$3,549.68",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9693,
            "parcelNumbers": 29748661670380,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2eb19bb2-2f62-4e12-8225-e3803a6a7db8",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/02/29 11:50:43 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/15/28 09:47:18 PM"
                }
              ]
            },
            {
              "id": "4f6493a7-d703-429e-bc63-e471f74af8c6",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/26/29 07:24:02 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/19/27 02:54:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/13/25 07:06:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/07/24 05:14:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/15/27 03:46:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/16/25 04:03:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/27 06:09:34 PM"
                }
              ]
            },
            {
              "id": "ed97e9aa-eb5c-4b1c-b3c0-e4289a32d71c",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/26 01:22:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/23/25 06:04:16 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "2cc437c8-91c5-45be-8896-a419ed306bb9",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1910,
          "address": "906 Harman Street, Bethpage, New Hampshire, 9647",
          "acreage": 9172,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "03/15/28 09:03:38 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 27,
          "bidAmount": "$3,590.93",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1758,
            "parcelNumbers": 27448763944507,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d6c5a0db-ddb3-4ff9-936f-a6e260d04dd7",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/13/25 10:00:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/27 09:37:16 AM"
                }
              ]
            },
            {
              "id": "83adaefa-e9ce-4169-a432-ef769c3ff462",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/30/26 05:58:53 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/16/29 10:39:03 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/13/27 12:46:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/03/27 02:34:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/28/27 08:22:09 AM"
                }
              ]
            },
            {
              "id": "0df59701-320a-4fe9-a933-517d9dd40fb4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/02/29 03:47:05 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/01/29 03:53:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/12/26 09:52:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/25/28 02:32:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/01/26 10:51:28 AM"
                }
              ]
            },
            {
              "id": "69b2e179-be53-40ab-86d4-9e289fe9ec68",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/09/29 03:18:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/29/28 08:08:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/29/27 04:19:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/02/27 05:40:57 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fb07bc44e701057ec",
      "groupName": "Dominos Deposits",
      "type": "proposal",
      "data": [
        {
          "id": "37731400-20d6-48af-857f-bb66dbc1f9a0",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3236,
          "address": "358 Kaufman Place, Rosburg, Mississippi, 5880",
          "acreage": 1164,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "08/25/28 08:45:28 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 31,
          "bidAmount": "$1,149.18",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4575,
            "parcelNumbers": 84793483848626,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "8247fb78-f8c4-492d-98f6-65a85f4329b0",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/08/27 09:57:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/25/30 08:43:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/25/26 08:35:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/19/24 09:08:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/23/25 05:50:19 AM"
                }
              ]
            },
            {
              "id": "99729259-a5c4-4444-83e7-ec7f8c386b75",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/30/25 08:05:48 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/01/29 11:51:27 PM"
                }
              ]
            },
            {
              "id": "09c067fa-05fc-419f-abaf-4b714a1682b9",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/06/25 05:08:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/04/26 03:04:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/12/27 01:38:44 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "5f289fcc-7643-4bee-a7b4-afbc4eeab3f5",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 795,
          "address": "109 Irving Street, Salvo, Connecticut, 9501",
          "acreage": 4361,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/10/26 04:59:18 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 51,
          "bidAmount": "$3,911.73",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2116,
            "parcelNumbers": 99451911207422,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "de211fdc-2426-4016-889b-16712c147285",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/25 08:44:28 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/05/25 01:57:17 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/26/25 12:55:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/27/29 12:59:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/30/25 12:06:00 PM"
                }
              ]
            },
            {
              "id": "431c354f-73dd-4ba8-87c4-d96e298e5099",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/03/24 03:24:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/21/27 05:30:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/12/29 05:08:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/16/27 04:22:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/30/26 01:33:58 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "57ddc74c-1afe-4161-aef4-1dd63c054418",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 512,
          "address": "858 Foster Avenue, Dunnavant, Virgin Islands, 3326",
          "acreage": 2696,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/02/29 10:45:15 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 6,
          "bidAmount": "$2,929.98",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5788,
            "parcelNumbers": 74469462840046,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "c8d74d06-f70e-4011-aa17-55c93b36eaf6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/12/26 07:29:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/29 01:01:26 AM"
                }
              ]
            },
            {
              "id": "c2927265-7b0a-4d0d-a23b-12c33216a873",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/28/26 05:25:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/16/27 09:34:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/01/27 08:29:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/19/29 11:14:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/06/28 06:47:40 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/02/29 05:08:35 AM"
                }
              ]
            },
            {
              "id": "78742e1c-dab0-4373-922f-6a4d49430e5d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/08/25 07:28:06 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/31/28 02:01:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/08/25 07:07:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/27/24 10:54:26 AM"
                }
              ]
            },
            {
              "id": "aff29af1-23bd-44ae-bbf3-837634ebbe48",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/07/28 05:37:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/03/26 11:35:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/14/27 05:01:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/31/29 08:30:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/27 12:50:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/07/25 08:29:46 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "ba88c360-93aa-4da3-9826-8837d0a0c5cb",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1572,
          "address": "946 Scott Avenue, Vallonia, West Virginia, 2337",
          "acreage": 4718,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "01/25/25 11:30:21 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 66,
          "bidAmount": "$2,627.47",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1842,
            "parcelNumbers": 17687461595566,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2ce44878-d665-4030-a906-44c074ee6602",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/22/26 05:38:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/17/29 10:40:41 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/01/27 04:22:16 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/06/26 05:36:34 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/20/26 11:28:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/06/29 08:40:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/09/26 05:57:16 AM"
                }
              ]
            },
            {
              "id": "ac0ad293-5db5-4bc1-8454-1d6d32078509",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/31/24 02:35:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/05/25 07:14:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/03/29 12:12:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/30/26 05:44:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/17/28 06:51:57 AM"
                }
              ]
            },
            {
              "id": "0a0f5d16-80a1-471d-a3f5-a320e37b1638",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/20/28 05:41:28 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/10/28 10:34:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/27/29 06:06:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/30/28 03:49:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/02/26 03:49:46 AM"
                }
              ]
            },
            {
              "id": "08f730df-ab6b-46b1-986d-0669c17ed634",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/19/28 09:08:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/21/25 03:01:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/26 04:47:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/29 11:56:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/24/29 03:06:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/27/26 07:06:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/11/28 08:45:57 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "00744dc9-683d-49c5-84db-04a2dba642b3",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2492,
          "address": "583 Alton Place, Laurelton, Oregon, 2296",
          "acreage": 2602,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "01/25/29 03:07:03 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 8,
          "bidAmount": "$3,613.52",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7245,
            "parcelNumbers": 77136617684960,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f392f790-37f0-4dc3-842e-c88e5cbf2801",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/08/26 04:45:59 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/19/28 01:33:17 AM"
                }
              ]
            },
            {
              "id": "e8383a36-e97e-419d-8383-c1674d0903e0",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/27 06:54:09 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/09/25 08:31:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/06/25 04:08:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/23/28 12:50:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/11/28 09:44:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/06/26 11:01:31 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/02/27 04:16:01 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "21fd3680-4da6-4e53-b360-2eabe7f2975d",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1056,
          "address": "702 Kensington Walk, Riner, Utah, 2321",
          "acreage": 9003,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/03/29 03:46:42 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 15,
          "bidAmount": "$3,651.48",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1262,
            "parcelNumbers": 70726841823231,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "96d9ecfc-0207-4025-aeff-6bc2233cc165",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/08/24 06:32:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/03/28 11:30:14 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/08/28 12:47:38 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/17/28 05:55:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/05/29 03:06:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/19/27 08:19:20 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/07/30 11:36:53 AM"
                }
              ]
            },
            {
              "id": "2ff72b30-adec-4ebe-a358-33d6a759b99e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/04/27 02:32:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/29 12:08:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/24/29 12:58:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/06/24 11:43:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/24/26 09:54:43 AM"
                }
              ]
            },
            {
              "id": "cfd3f7c3-7c8e-4beb-b5dd-95f0f15db517",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/27/25 11:14:38 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/24/25 08:00:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/19/29 08:35:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/26 10:37:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/04/29 05:35:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/14/27 07:57:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/05/28 05:26:34 AM"
                }
              ]
            },
            {
              "id": "aa778c78-cb12-42ea-82d0-b5e6800f0a01",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/17/24 01:28:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/11/27 08:26:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/21/28 08:06:21 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/04/26 07:52:18 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/17/25 06:56:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/14/29 12:11:22 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fc2601e2e03b90e94",
      "groupName": "Dominos Deposits",
      "type": "invitation",
      "data": [
        {
          "id": "fcd68207-81e7-47f2-b64e-4d1d487ce87b",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2239,
          "address": "643 McKinley Avenue, Logan, American Samoa, 5903",
          "acreage": 3271,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "01/25/25 01:18:45 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 43,
          "bidAmount": "$1,826.58",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5146,
            "parcelNumbers": 27587530771445,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f55aa53d-bc0e-4516-96fb-caf1ab4472b9",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/27 02:46:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/29 10:38:26 PM"
                }
              ]
            },
            {
              "id": "84367b8b-e87f-428a-bb3c-c4986d0261a4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/22/29 11:02:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/15/26 12:49:07 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/21/29 04:04:08 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/19/24 11:32:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/01/27 07:14:31 PM"
                }
              ]
            },
            {
              "id": "ad428772-986a-46f8-8def-e8b951107e89",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/13/26 03:15:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/23/28 06:35:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/26/25 09:15:48 AM"
                }
              ]
            },
            {
              "id": "471e9f38-247a-4daa-beb6-97798083c7b6",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/07/28 11:31:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/20/28 11:04:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/11/28 02:15:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/20/24 07:17:17 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/01/27 06:06:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/20/30 07:13:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/22/26 09:34:55 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "6a9d919a-20c1-4cc3-b102-03fe2550287a",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2636,
          "address": "965 Utica Avenue, Greenock, Nevada, 1092",
          "acreage": 3654,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "11/15/28 12:45:16 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 61,
          "bidAmount": "$1,212.91",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5410,
            "parcelNumbers": 70761822199544,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "b5a50a00-3bb4-42a2-ab03-088a63300c30",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/29/26 05:21:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/31/29 03:35:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/31/24 03:12:44 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/26/26 02:35:26 AM"
                }
              ]
            },
            {
              "id": "299430fe-0eab-4db4-bf43-571ee9c763a6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/28 02:50:50 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/22/27 08:20:32 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "fb3dca05-ad70-4c42-ad1c-24e91aa7cc47",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1346,
          "address": "326 Concord Street, Oneida, Montana, 7878",
          "acreage": 5429,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "07/26/26 01:46:27 PM",
          "documents": [],
          "turnTime": 46,
          "bidAmount": "$1,916.31",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5849,
            "parcelNumbers": 94487244762577,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "eb276a77-288a-42b6-99c4-1570915358b3",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/26/29 02:54:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/28/27 03:53:03 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/29/26 09:46:47 PM"
                }
              ]
            },
            {
              "id": "d8306e26-3c9a-4106-b735-73bf2a8d53a0",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/21/26 09:38:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/07/27 10:58:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/12/29 12:22:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/22/25 03:06:32 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "f58dec0a-47f9-42c2-abf4-7208c0078a0e",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3373,
          "address": "252 Seagate Terrace, Katonah, California, 4787",
          "acreage": 3305,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "01/24/30 08:30:36 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 12,
          "bidAmount": "$2,216.43",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 4,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1028,
            "parcelNumbers": 67105600014447,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d728d4b1-d6e0-4437-8554-198740198ab2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/20/27 09:22:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/12/27 07:53:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/14/24 11:12:41 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/14/27 03:18:59 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/15/27 05:26:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/23/24 10:08:02 AM"
                }
              ]
            },
            {
              "id": "65f3ef4d-7499-4d94-9b1c-a38621bc19d6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/15/29 06:09:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/27 03:23:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/28/24 11:46:57 PM"
                }
              ]
            },
            {
              "id": "5c35e0f3-bc21-483e-86fa-4f58ff158a73",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/25/28 04:40:45 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/27/26 06:02:27 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/26/25 12:58:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/28 09:29:35 AM"
                }
              ]
            },
            {
              "id": "a1b32122-9403-4ab3-8b6b-8e5d1da2f7a2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/29 01:18:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/21/26 01:13:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/12/27 05:47:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/16/25 10:50:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/24/29 09:48:35 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/10/26 03:22:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/24/26 04:38:46 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "8352f98d-2671-425e-ae08-dae655550598",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 2769,
          "address": "205 Martense Street, Cutter, Georgia, 7728",
          "acreage": 3727,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/12/28 04:06:00 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 7,
          "bidAmount": "$3,228.04",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7837,
            "parcelNumbers": 81515374416934,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "39b5ccb9-d1df-4092-b3e3-ff1411dcee44",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/10/28 01:22:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/01/29 02:44:14 PM"
                }
              ]
            },
            {
              "id": "162a3e0a-cfda-457e-8b48-b842f627f927",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/28 11:48:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/27 10:23:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/24 10:16:55 AM"
                }
              ]
            },
            {
              "id": "ca14f0b4-d66b-4df8-9af8-9894b1dbe62b",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/01/24 02:46:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/26 04:46:10 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "b0cce8b3-e77c-4dc3-817c-438fc2aaab07",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1408,
          "address": "389 Maujer Street, Caron, Virginia, 4592",
          "acreage": 8918,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/11/29 11:59:00 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 14,
          "bidAmount": "$1,345.13",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5239,
            "parcelNumbers": 65226605325936,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f4545fc8-7bae-4bdb-bcc5-05a411ab560a",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/13/25 09:35:13 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/31/26 11:38:31 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/22/29 07:58:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/29 11:16:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/22/25 08:58:18 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/27 07:13:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/24/27 06:43:17 PM"
                }
              ]
            },
            {
              "id": "d1eaf774-397f-4edc-a248-10dcc0c58107",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/02/28 04:32:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/07/24 06:53:35 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/29 03:37:40 PM"
                }
              ]
            },
            {
              "id": "0fa4f02c-051b-4224-9b08-22808fa3fd71",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/03/25 11:35:05 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/15/25 01:24:28 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/25/29 07:01:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/28/25 06:34:44 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/04/28 11:50:50 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/07/29 09:46:47 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "046b2639-018e-4e99-a627-c29ae698b299",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1737,
          "address": "222 Adelphi Street, Caledonia, Oklahoma, 9469",
          "acreage": 9873,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "03/06/25 07:24:45 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 52,
          "bidAmount": "$1,122.08",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 11,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9661,
            "parcelNumbers": 98507645664387,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "04e3e3b2-7099-4419-9158-a4c30a5a0b56",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/20/26 02:21:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/08/26 10:25:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/06/27 10:02:27 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/04/27 04:44:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/20/28 09:46:24 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/25/26 08:09:13 PM"
                }
              ]
            },
            {
              "id": "b7584d26-fb6e-4570-9126-616edd28d08a",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/07/24 06:20:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/24/29 04:35:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/04/28 06:24:27 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/08/24 03:38:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/26/25 11:55:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/20/28 10:30:05 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/20/28 08:24:18 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f5050435c9f6aecc4",
      "groupName": "Dominos Deposits",
      "type": "active",
      "data": [
        {
          "id": "c0ff04ac-3c2c-4b60-b52e-8d387b09a393",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 962,
          "address": "384 Imlay Street, Robbins, Massachusetts, 5402",
          "acreage": 2943,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "03/19/25 03:17:56 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 82,
          "bidAmount": "$1,568.84",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4221,
            "parcelNumbers": 38372101745248,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "6f624aad-6e8c-4f0f-a6a8-5fe4f822a226",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/27/25 06:29:04 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/28/29 11:25:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/05/28 09:20:20 AM"
                }
              ]
            },
            {
              "id": "44a7582b-01de-4087-b31d-5145d2fa0730",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/25 06:06:45 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/21/26 06:17:19 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/01/29 02:45:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/24/24 10:57:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/04/26 05:53:09 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/12/28 04:39:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/09/29 03:05:47 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "d957e264-6457-4e75-9d97-2e3bb4a53a17",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3546,
          "address": "696 Fane Court, Lloyd, Alabama, 8266",
          "acreage": 5742,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/13/28 08:39:10 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 63,
          "bidAmount": "$1,027.75",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5021,
            "parcelNumbers": 39708502656401,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "da391759-5d10-432b-96d0-bb797f43f8f9",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/21/28 01:23:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/09/29 02:12:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/15/29 11:24:15 AM"
                }
              ]
            },
            {
              "id": "82a534f3-3dbc-46d6-90e3-8a2236fc09b1",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/09/27 01:20:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/11/26 10:10:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/24/28 04:29:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/05/28 04:59:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/05/27 05:56:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/02/27 11:49:46 PM"
                }
              ]
            },
            {
              "id": "aea855ee-53f8-4f73-8176-d08b6adc1235",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/09/25 02:21:03 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/27/29 08:07:24 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ce01d574-a966-409a-8ad1-ffbe87c8b9c5",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3384,
          "address": "141 Montauk Avenue, Herbster, Puerto Rico, 4389",
          "acreage": 4082,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/06/25 07:50:41 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 3,
          "bidAmount": "$1,830.15",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 11,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4537,
            "parcelNumbers": 57842224944559,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "8b51bff8-6344-425a-b73e-a2665cba7891",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/29 04:29:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/10/25 03:37:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/12/26 09:20:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/27 01:41:54 AM"
                }
              ]
            },
            {
              "id": "43cde859-37a1-4211-8c52-f3d01bfcacba",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/27 03:56:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/05/30 05:41:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/13/25 07:16:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/27/27 05:02:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/09/27 03:41:45 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/07/27 03:40:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/13/27 01:28:09 PM"
                }
              ]
            },
            {
              "id": "fa5ceefb-638b-426a-9a8b-39bcc12cd806",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/10/29 09:54:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/31/27 12:57:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/15/27 10:10:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/24/27 07:00:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/29 03:59:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/26 07:06:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/20/26 10:29:29 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "6e74e4b1-a3ad-4ddd-9a3a-dbcc2b5554eb",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 191,
          "address": "631 Fillmore Place, Gratton, Kansas, 6826",
          "acreage": 8000,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "03/31/26 06:47:05 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 5,
          "bidAmount": "$3,509.61",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7734,
            "parcelNumbers": 81380093904681,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e64b9ef9-b198-442c-bd74-164faec84009",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/27 05:02:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/27/27 01:08:03 PM"
                }
              ]
            },
            {
              "id": "e9c13110-a2d4-44dd-8604-cc516fcd1712",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/08/27 02:50:19 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/21/29 06:23:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/05/25 09:56:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/03/27 11:21:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/22/26 07:56:24 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/10/25 09:23:50 PM"
                }
              ]
            },
            {
              "id": "a0d822ed-7114-43f4-afb4-f7d1e0dc3f51",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/25/28 09:37:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/19/27 08:29:41 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/30/26 04:04:45 AM"
                }
              ]
            },
            {
              "id": "740baeeb-7cd2-47ee-9682-5ceda9b0ece1",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/19/25 05:54:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/05/29 02:27:17 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/25 04:32:31 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/04/26 09:48:32 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "44df9121-e79e-4e98-8e2a-2fbaf98e88e2",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3709,
          "address": "828 River Street, Marshall, Nebraska, 8244",
          "acreage": 3467,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "02/06/25 06:27:18 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 21,
          "bidAmount": "$1,595.30",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2213,
            "parcelNumbers": 87345453247549,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e1c2833d-f18c-4abe-97ed-f88985113deb",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/24 12:44:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/01/27 11:19:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/12/27 06:00:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/22/28 05:03:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/16/24 09:09:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/19/25 10:48:18 AM"
                }
              ]
            },
            {
              "id": "129ac773-cb04-49de-af11-08d13995027e",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/15/29 08:08:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/26 12:39:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/01/28 10:49:53 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/26/29 02:45:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/27 03:14:20 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/28/28 03:23:38 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/11/29 05:39:52 PM"
                }
              ]
            },
            {
              "id": "6dc6afa0-1347-4aee-af97-71203dfffb7b",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/17/29 02:03:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/25/24 08:49:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/29 03:25:38 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/30/27 08:42:32 PM"
                }
              ]
            },
            {
              "id": "b92b1f25-0dcf-4a08-8e92-063d1c2bc80a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/15/26 04:23:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/29/27 04:14:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/06/29 10:19:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/25/25 01:29:44 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f57e88594bfe0ac6a",
      "groupName": "Burger King Coffers",
      "type": "active",
      "data": [
        {
          "id": "945295cc-0c67-49ea-9313-468d89623d9f",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1261,
          "address": "304 Canal Avenue, Esmont, Idaho, 4087",
          "acreage": 7789,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "04/10/28 03:04:01 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 47,
          "bidAmount": "$1,832.44",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8718,
            "parcelNumbers": 82705969458905,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "3aeff2eb-f3f1-40b4-9ec6-e8d1bdb0f9aa",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/27/25 03:23:52 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/31/27 01:58:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/03/28 10:58:21 AM"
                }
              ]
            },
            {
              "id": "9b4ce2c5-ef2f-4ce3-8cb0-d7e409dca0a6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/12/29 06:17:08 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/15/27 01:48:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/03/28 10:48:31 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/07/25 02:57:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/14/24 03:32:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/24 10:23:31 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "3d79dd7b-b739-470e-b501-3388e88fb0e0",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2214,
          "address": "621 Bristol Street, Wedgewood, South Carolina, 8803",
          "acreage": 6979,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/23/27 11:59:19 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 3,
          "bidAmount": "$3,383.44",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7813,
            "parcelNumbers": 43466468904133,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "753b20b9-b3dc-478d-84e0-47350b3878da",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/17/27 05:48:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/25/24 05:36:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/25 12:06:17 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/17/27 07:35:42 PM"
                }
              ]
            },
            {
              "id": "f67859f8-e3c5-4119-94ea-128ed7be7e13",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/17/25 10:35:56 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/15/26 07:39:16 PM"
                }
              ]
            },
            {
              "id": "7660e4c6-99a6-453f-bc2d-9321696a56ae",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/07/29 08:34:57 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/22/25 08:13:17 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/06/29 01:42:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/07/28 03:35:52 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/16/26 05:24:59 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/18/28 06:55:26 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "af77fe97-e428-4453-9df4-40c5de0fda6c",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 2153,
          "address": "638 Stockholm Street, Grimsley, Ohio, 8848",
          "acreage": 5510,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "04/11/25 08:45:03 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 47,
          "bidAmount": "$1,649.93",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6789,
            "parcelNumbers": 66159693615238,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a0db134b-3c48-43bf-a692-6560b0a17d09",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/27/28 02:47:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/11/25 10:15:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/02/29 12:20:37 AM"
                }
              ]
            },
            {
              "id": "bf7db046-ffe5-49cc-9643-61351d3e9765",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/31/27 03:12:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/16/26 08:51:11 PM"
                }
              ]
            },
            {
              "id": "761dacbe-0009-4652-8509-37914ed0a74e",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/16/29 01:14:05 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/06/24 10:25:48 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/24/26 04:13:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/09/25 10:42:58 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "956d2676-7233-4b45-89b6-2fd863a325b5",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1801,
          "address": "942 Bath Avenue, Fresno, Guam, 4119",
          "acreage": 2108,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "07/13/28 09:12:35 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 7,
          "bidAmount": "$2,779.18",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 20,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8916,
            "parcelNumbers": 21988479167067,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "99426da7-9238-45c7-9fc9-c64b1dd21c26",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/20/26 01:55:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/04/25 04:15:57 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/08/27 06:56:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/09/27 10:20:08 PM"
                }
              ]
            },
            {
              "id": "c255cddf-81e6-41a0-a13a-e6c7e8926b00",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/24/27 10:51:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/25 06:31:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/07/29 10:46:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/28/27 04:08:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/18/24 12:08:24 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "afd5758b-4f5d-422e-a843-476fff8bf61c",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 236,
          "address": "658 Guider Avenue, Hillsboro, Illinois, 6663",
          "acreage": 6371,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "05/23/29 07:24:54 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 59,
          "bidAmount": "$1,075.10",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5432,
            "parcelNumbers": 40722446665053,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "41aeb76d-bd87-4129-82b8-336d4f52c050",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/13/27 09:28:05 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/28/27 03:43:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/20/29 12:30:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/23/28 05:01:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/03/26 01:54:04 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/24 10:25:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/25/24 09:18:14 AM"
                }
              ]
            },
            {
              "id": "6750c7b5-ddd7-420c-b344-57ccdf89d551",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/26 10:56:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/29/25 06:01:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/28/24 11:07:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/25 06:28:51 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/18/25 03:51:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/20/29 02:27:41 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f1778c70682edbfbd",
      "groupName": "Burger King Coffers",
      "type": "invitation",
      "data": [
        {
          "id": "097d8d50-37bf-4fdc-986a-6381549c7039",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 845,
          "address": "571 Himrod Street, Whitewater, New York, 2810",
          "acreage": 4963,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "12/06/24 05:33:54 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 56,
          "bidAmount": "$2,443.56",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2655,
            "parcelNumbers": 35806810111721,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "eb4efb84-8bd2-447c-a240-a1e2bd132c84",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/26/27 08:17:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/08/28 03:09:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/09/25 04:08:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/25/26 01:58:12 AM"
                }
              ]
            },
            {
              "id": "a9f4904e-3941-4f26-be45-b95ba6a288af",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/22/27 10:39:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/13/27 03:49:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/26/28 06:39:40 AM"
                }
              ]
            },
            {
              "id": "51a670db-ade5-4048-a20e-ff2b502179ef",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/12/28 01:15:04 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/15/29 12:41:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/15/25 04:26:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/25 05:14:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/21/25 09:10:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/27/25 08:57:21 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/07/26 06:36:24 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "603cdc96-6242-462e-b913-248421288254",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1109,
          "address": "540 Navy Street, Teasdale, Wisconsin, 4447",
          "acreage": 6977,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/19/26 08:08:01 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$1,075.52",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4136,
            "parcelNumbers": 65432861844361,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "22fd3595-c2de-4679-a58b-a47a84d1c1ec",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/19/25 11:24:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/21/26 06:13:05 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/06/29 06:47:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/18/27 03:38:38 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/06/28 06:16:15 PM"
                }
              ]
            },
            {
              "id": "f3b91abf-af2c-439e-a23e-1cddb4b93d5c",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/09/29 12:10:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/13/29 01:52:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/10/28 08:31:16 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/28 04:16:17 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "4f48865c-9d3b-4c7c-9a98-197bafd63825",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2076,
          "address": "207 Luquer Street, Breinigsville, District Of Columbia, 6669",
          "acreage": 3220,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "08/28/29 01:12:17 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 50,
          "bidAmount": "$2,534.12",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4657,
            "parcelNumbers": 83669533333081,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1e5ee4fd-48ea-4577-8093-ab0911eb673c",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/05/26 02:02:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/25/28 10:04:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/15/24 04:28:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/30/27 02:34:16 PM"
                }
              ]
            },
            {
              "id": "57d61ae7-90b6-47cd-a519-f3ab1aa9cbad",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/21/29 01:50:35 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/27 08:13:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/28 12:49:30 PM"
                }
              ]
            },
            {
              "id": "5539ca01-2e57-451c-9d18-04021a01f416",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/14/26 07:20:48 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/07/29 07:45:06 PM"
                }
              ]
            },
            {
              "id": "feb0d020-6a4c-4a93-9060-93b6dcb896ee",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/29/29 02:18:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/04/26 01:47:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/14/25 07:29:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/31/29 03:13:49 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/11/27 07:10:24 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "2b4f4bef-4689-4dfd-ba4a-cdbc42e036f5",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3053,
          "address": "621 Caton Place, Lacomb, Marshall Islands, 7310",
          "acreage": 1189,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/27/29 11:47:19 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 81,
          "bidAmount": "$2,762.60",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2816,
            "parcelNumbers": 15853595925088,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "5a4d6300-27be-4e15-86ee-4636c955883a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/23/27 08:35:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/02/27 03:26:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/18/24 03:55:13 PM"
                }
              ]
            },
            {
              "id": "80f2d7c7-a9a8-48e4-83e4-090a6c4e5085",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/23/25 03:15:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/05/26 10:17:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/14/28 11:24:20 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "397cfdc0-9624-4cb6-acba-48e48077b503",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 1930,
          "address": "804 Ridge Court, Machias, Louisiana, 840",
          "acreage": 4215,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "01/28/30 04:17:50 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 41,
          "bidAmount": "$1,812.34",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7365,
            "parcelNumbers": 21587472745726,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "eb0e9acd-c6bd-4c45-8404-3e16bea1e7f4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/22/28 11:35:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/28/28 07:14:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/28 05:52:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/04/25 10:30:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/14/27 07:09:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/10/28 02:50:42 PM"
                }
              ]
            },
            {
              "id": "94248a2c-1659-4478-8e64-21306d800939",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/27/29 01:17:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/23/28 08:48:33 AM"
                }
              ]
            },
            {
              "id": "09802e8f-b6c3-475e-8a58-994a0a0707a7",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/27/25 08:29:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/29 04:20:18 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/10/27 12:25:35 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/03/24 04:12:27 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "0592b147-a62b-4795-a3c4-883cc002e8f9",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2707,
          "address": "556 Virginia Place, Forbestown, Iowa, 1845",
          "acreage": 1141,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "05/22/26 03:13:44 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 94,
          "bidAmount": "$2,704.09",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 18,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9650,
            "parcelNumbers": 49613506249740,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "43eacd40-ccf5-4d9e-93e6-4ddf63a93afa",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/18/26 05:23:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/01/27 02:53:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/12/29 08:22:48 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/16/26 05:43:07 PM"
                }
              ]
            },
            {
              "id": "9181a5a9-7c7d-48ba-a057-dcf38436f5e4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/14/24 10:52:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/07/29 11:14:36 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/11/25 11:00:01 PM"
                }
              ]
            },
            {
              "id": "dd0a6896-7716-4e7b-9811-4d7fcad78cd5",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/29/26 04:47:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/19/28 10:14:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/16/27 08:36:36 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "61097ac8-2a7a-4834-aaa6-256f57108f8d",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2325,
          "address": "788 Times Placez, Cressey, Alaska, 8585",
          "acreage": 5877,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "12/16/27 09:24:19 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 99,
          "bidAmount": "$2,626.70",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1431,
            "parcelNumbers": 92802996433632,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "28ff16a4-5a71-43c8-a569-1ef5ff51fdda",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/19/29 05:23:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/18/28 04:22:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/29/29 07:41:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/11/26 03:10:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/23/29 01:13:34 PM"
                }
              ]
            },
            {
              "id": "b3ebcb9c-e31d-4273-8de8-da9566160423",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/16/28 12:29:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/02/25 05:36:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/26 07:55:03 AM"
                }
              ]
            },
            {
              "id": "1b80863b-6e89-407b-a769-3e8cbc19557c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/19/28 09:07:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/02/28 12:13:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/24 01:21:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/10/25 09:10:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/12/25 01:04:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/24/25 01:32:48 PM"
                }
              ]
            },
            {
              "id": "5d4505eb-a7e3-4019-a5cd-491ceab59c4f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/28/29 11:20:04 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/09/28 01:29:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/29 05:55:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/09/27 07:25:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/30/26 12:53:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/01/25 08:41:07 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f1ecb31c43a4a3147",
      "groupName": "Taco Bell Holdings",
      "type": "proposal",
      "data": [
        {
          "id": "df0d0876-ee42-4aab-8894-6d92b768e8f3",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1549,
          "address": "514 Atkins Avenue, Eagletown, Federated States Of Micronesia, 2460",
          "acreage": 8402,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/26/26 06:44:36 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 98,
          "bidAmount": "$3,743.41",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6110,
            "parcelNumbers": 21470466629529,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "676bb200-644e-4371-845b-1e7ca8733bc2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/10/28 02:36:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/01/26 09:42:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/01/29 08:42:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/25 05:48:37 PM"
                }
              ]
            },
            {
              "id": "996ab04f-202d-4d98-9fac-de9de8aa5165",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/08/26 08:35:37 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/29/27 04:37:41 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "aea3283b-d68c-44e8-b017-788165ef8159",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1687,
          "address": "815 Norwood Avenue, Turpin, Rhode Island, 4847",
          "acreage": 3292,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/16/26 08:01:14 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 47,
          "bidAmount": "$1,535.13",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2974,
            "parcelNumbers": 84502829477103,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "8c9cc7e7-9625-42a1-afb5-84dfcf7d91ae",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/14/28 01:04:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/05/25 05:15:18 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/10/27 11:04:28 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/10/27 08:55:09 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/19/29 07:14:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/26 03:35:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/05/27 07:06:38 AM"
                }
              ]
            },
            {
              "id": "0e775d2b-e65d-4694-8e1c-de100ec89f47",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/23/25 06:04:31 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/15/25 10:39:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/30/26 12:45:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/05/25 12:23:38 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "99fa5e52-fecc-416f-a945-bb25196b93ad",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1882,
          "address": "166 Bayview Avenue, Worton, Missouri, 3464",
          "acreage": 6153,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/20/24 06:10:29 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 15,
          "bidAmount": "$3,070.47",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9866,
            "parcelNumbers": 36212690993064,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d310b617-ab96-4e86-a1ff-c475280c0a10",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/04/25 08:13:48 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/16/24 06:49:45 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/10/26 03:19:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/05/24 08:50:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/21/25 10:54:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/19/26 10:58:42 AM"
                }
              ]
            },
            {
              "id": "46bca4c0-ab59-4e4e-b9e5-8aebdd5bb7e8",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/04/28 03:37:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/31/25 10:11:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/29/29 03:27:40 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "aae22b90-0eec-4c28-beff-bf092cfad635",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 571,
          "address": "429 Hunts Lane, Weeksville, Northern Mariana Islands, 9019",
          "acreage": 3917,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "02/02/27 01:38:25 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 81,
          "bidAmount": "$2,249.68",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1434,
            "parcelNumbers": 33515793255113,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e21b870c-d6ce-428f-a1e9-e05fddeb1302",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/22/27 05:38:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/16/25 03:28:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/25/26 05:33:44 AM"
                }
              ]
            },
            {
              "id": "dea1b722-48a8-4501-99cd-a49ccd9c372b",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/30/29 03:29:34 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/09/26 06:11:28 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/27/26 03:39:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/07/25 04:08:12 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/15/27 03:04:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/03/25 11:55:22 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "105ed271-557f-4ed3-8bf3-2eafdcba18d3",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1029,
          "address": "726 Bedford Avenue, Waterford, Arkansas, 7509",
          "acreage": 7073,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/04/27 05:15:37 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 9,
          "bidAmount": "$3,562.59",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 18,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6443,
            "parcelNumbers": 42981848667597,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a29292cb-d6fd-4967-ae10-8d46a01526a7",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/22/25 03:34:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/26 05:51:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/25 05:18:06 PM"
                }
              ]
            },
            {
              "id": "1e2dec9c-ad1a-42ed-88a8-42c49ad4bc74",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/19/28 09:05:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/01/29 12:21:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/18/25 06:03:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/22/28 10:12:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/16/29 02:22:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/12/29 01:38:50 AM"
                }
              ]
            },
            {
              "id": "c060fb81-aa5c-48da-bee6-868ad5a43094",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/26/27 10:53:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/15/26 09:41:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/14/25 01:41:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/27 08:16:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/26 10:24:42 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f81bb92fe27507e29",
      "groupName": "Dominos Deposits",
      "type": "active",
      "data": [
        {
          "id": "83dcc147-fba7-4aaf-97f3-3f9491689a8c",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1002,
          "address": "509 Falmouth Street, Townsend, South Dakota, 8474",
          "acreage": 3933,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "01/10/27 10:38:23 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 31,
          "bidAmount": "$2,622.37",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4228,
            "parcelNumbers": 53798516169813,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "630f624c-9a27-46f5-977d-bee62ef7ce85",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/13/29 11:01:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/27 11:23:44 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/11/26 08:07:56 AM"
                }
              ]
            },
            {
              "id": "87ce54fa-7e56-41ab-8975-b09d2c72c6f7",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/29/27 02:11:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/18/25 04:15:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/06/29 10:50:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/10/28 12:25:55 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "1fa4efc1-9c2a-4b66-8d01-6647458fb3e8",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3754,
          "address": "652 Hanover Place, Bloomington, Palau, 5488",
          "acreage": 8270,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "01/19/29 11:40:33 AM",
          "documents": [],
          "turnTime": 66,
          "bidAmount": "$2,436.71",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6580,
            "parcelNumbers": 83190016714439,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f8aff94b-3949-459a-be47-2e9be00e488b",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/21/25 06:10:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/15/29 07:54:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/21/26 06:59:02 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/30 07:50:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/28 06:40:26 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/12/27 05:29:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/31/28 07:50:06 AM"
                }
              ]
            },
            {
              "id": "f3387d4c-306a-4cac-a3ce-8637f58ee89d",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/14/29 06:39:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/04/24 05:54:46 PM"
                }
              ]
            },
            {
              "id": "e9b7193c-a3fe-4555-b83f-051867e80efe",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/22/29 06:37:16 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/27 05:27:45 PM"
                }
              ]
            },
            {
              "id": "87ad10c2-7cd2-480e-aa79-24f890212f21",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/19/24 10:43:40 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/16/27 01:16:43 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "cb2eeae7-54e8-4964-a6c9-0d12cf10eb68",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2427,
          "address": "802 Richardson Street, Nord, Colorado, 3832",
          "acreage": 5557,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "05/20/28 06:21:28 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 81,
          "bidAmount": "$2,317.06",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5492,
            "parcelNumbers": 36598395123799,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f4b76b14-aeb2-4f4b-9ded-d2b736e4fecb",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/16/27 02:48:21 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/06/24 12:48:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/13/30 12:37:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/11/30 06:39:02 AM"
                }
              ]
            },
            {
              "id": "54c789da-30f3-4e34-a69a-9333f8ae46e0",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/27 05:22:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/21/24 05:34:19 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "6d185873-a506-436e-bdc1-c371c31df5aa",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2224,
          "address": "275 Manhattan Court, Rivera, Kentucky, 8975",
          "acreage": 8597,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "05/30/29 02:26:45 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 4,
          "bidAmount": "$1,713.27",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 4,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2219,
            "parcelNumbers": 42136269712537,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "5350c2b7-1ad8-4dd5-b11a-7c9763238286",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/07/28 10:42:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/26 06:53:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/15/25 04:53:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/28/27 11:17:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/17/29 02:33:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/16/29 10:51:00 PM"
                }
              ]
            },
            {
              "id": "20fabd4e-606b-4118-a547-e36b5a622878",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/08/26 06:01:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/02/27 03:25:11 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "116d51bf-f048-409e-a5dd-68de39ad8632",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1120,
          "address": "776 Beverly Road, Cartwright, Delaware, 5492",
          "acreage": 8112,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/17/27 07:56:37 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 100,
          "bidAmount": "$1,622.02",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4783,
            "parcelNumbers": 68081126031392,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "0140a2ef-2297-476d-a741-b087d7e88e6e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/28/27 10:41:35 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/27/26 04:34:37 AM"
                }
              ]
            },
            {
              "id": "e9828919-a291-4f41-948e-d8f832848835",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/27/27 08:49:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/12/28 01:22:53 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/08/29 08:14:38 AM"
                }
              ]
            },
            {
              "id": "bf940889-5292-45fb-83eb-c23df139d561",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/09/29 09:53:31 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/23/25 06:20:15 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "91f383f2-1a9f-4b62-8aa9-1dc7d3fddb25",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 353,
          "address": "816 Boardwalk , Adelino, Maine, 3080",
          "acreage": 9471,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "01/17/26 06:48:21 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$2,299.03",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8458,
            "parcelNumbers": 42772080468562,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "91372f27-ed1f-416c-8acf-348c43aab9b5",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/03/28 05:53:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/05/26 04:32:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/30/28 07:33:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/26 05:43:13 AM"
                }
              ]
            },
            {
              "id": "96349c10-cc81-40f8-b4d3-9c7328c240a2",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/20/27 10:15:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/13/29 02:39:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/08/27 03:46:00 AM"
                }
              ]
            },
            {
              "id": "b7ec42ef-941e-4b98-bb96-75258c5ebbe9",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/11/26 05:31:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/05/27 04:51:57 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/27/26 11:54:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/09/27 09:18:10 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "c5a7d729-7b47-4163-85cd-14ac582fe26d",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3263,
          "address": "590 Woodhull Street, Innsbrook, New Jersey, 5064",
          "acreage": 5694,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "09/01/28 04:33:32 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 4,
          "bidAmount": "$1,170.91",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 11,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8161,
            "parcelNumbers": 39043612868974,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "85b9db7a-ba16-4229-ada0-3ef85fe37dbf",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/30/26 08:56:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/21/25 06:50:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/09/25 06:33:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/22/25 06:30:55 PM"
                }
              ]
            },
            {
              "id": "4565b204-e775-4a5a-8e2d-07a8f87f4964",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/26 03:42:09 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/22/25 10:57:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/24/29 12:26:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/08/27 04:24:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/28 08:16:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/05/25 10:33:05 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/30/25 12:47:28 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00ffd505486e67a13d0",
      "groupName": "Taco Bell Holdings",
      "type": "invitation",
      "data": [
        {
          "id": "d2f26091-1344-4d11-84c2-04691bce5f53",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3462,
          "address": "856 Macon Street, Catherine, Indiana, 7316",
          "acreage": 8288,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "07/10/26 03:48:41 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 88,
          "bidAmount": "$3,018.49",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5893,
            "parcelNumbers": 54390992284469,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "0d9480d8-b7e9-4ced-ad87-ac260ba4e46a",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/20/30 01:28:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/03/26 10:19:41 PM"
                }
              ]
            },
            {
              "id": "74127129-12c1-4762-bd1e-f63e04502367",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/02/26 05:11:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/04/25 10:21:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/17/25 07:14:49 AM"
                }
              ]
            },
            {
              "id": "54f908d4-e547-49dd-b391-a929900efda6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/21/27 03:11:01 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/25 12:45:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/20/25 03:51:24 AM"
                }
              ]
            },
            {
              "id": "af947f74-739e-4650-b1d4-04139af58da3",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/03/27 08:41:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/01/29 08:16:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/02/26 02:09:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/16/28 05:27:07 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "6ecd1e06-85c1-4b81-bbdb-4cba8019cc1c",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1238,
          "address": "563 Canarsie Road, Glenshaw, Minnesota, 1149",
          "acreage": 8182,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "05/19/25 09:22:18 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 92,
          "bidAmount": "$1,180.24",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4784,
            "parcelNumbers": 21331775248868,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "6e13fa97-6a89-4e3a-b3f2-88db4068d277",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/05/25 07:31:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/28/27 05:11:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/03/28 07:09:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/03/27 04:54:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/27 01:02:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/23/28 03:40:53 PM"
                }
              ]
            },
            {
              "id": "2a4c6ba8-6b5e-408c-b2f6-80ba7ac3ef48",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/12/28 06:53:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/21/29 05:37:46 PM"
                }
              ]
            },
            {
              "id": "afd2f3c3-5526-4cc3-a345-cda8664c7803",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/12/29 01:50:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/07/28 12:26:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/22/28 01:15:54 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/27 01:36:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/11/28 12:12:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/03/29 12:38:29 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/16/25 07:26:40 AM"
                }
              ]
            },
            {
              "id": "bcb93a06-c784-4bfc-abe7-a0bf8fef5d92",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/29/30 08:28:10 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/03/27 05:02:59 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "901f15d9-e498-4aaa-b350-a3399080b724",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1889,
          "address": "939 Diamond Street, Noxen, Hawaii, 7731",
          "acreage": 5128,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/18/27 09:08:46 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 31,
          "bidAmount": "$2,843.93",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9579,
            "parcelNumbers": 51976434097561,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f5b701f2-6ea4-4425-94ba-774b082a403e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/21/25 05:27:41 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/07/25 01:43:34 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/15/30 03:09:32 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/28 06:59:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/26 05:09:29 PM"
                }
              ]
            },
            {
              "id": "4b1bc6a2-ebac-40de-a7bb-dc0e86610b31",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/26/26 11:57:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/09/27 07:57:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/28/29 01:09:00 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/25 11:53:29 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/08/28 09:40:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/19/25 02:52:55 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "abaa2d01-24ec-45f2-8ebc-49d995f97325",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2774,
          "address": "406 Durland Place, Ripley, Washington, 4878",
          "acreage": 8303,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "04/15/28 02:50:23 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 22,
          "bidAmount": "$3,417.02",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 17,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5710,
            "parcelNumbers": 60724679701174,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "4abf362c-5f6a-475a-a43c-58d41e0ae5a5",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/25 01:05:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/08/28 05:03:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/05/25 12:44:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/26 03:15:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/03/26 08:10:12 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/09/25 09:59:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/18/29 09:03:34 PM"
                }
              ]
            },
            {
              "id": "24b3f42e-a0f3-47d0-8db9-d7136a0a4238",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/26/25 01:16:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/26/26 05:37:06 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/06/27 09:26:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/22/27 07:54:57 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ed831b85-52b9-4d2b-ac33-95f7e0707410",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2216,
          "address": "551 Powers Street, Yorklyn, Florida, 475",
          "acreage": 1146,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "04/06/26 11:00:32 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 88,
          "bidAmount": "$3,558.20",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6429,
            "parcelNumbers": 98927804470663,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "0937e912-4425-44fa-a6e8-52029a661643",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/25 07:25:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/07/26 09:27:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/24/29 10:46:16 AM"
                }
              ]
            },
            {
              "id": "69f57990-4b30-4795-bbff-a0ee0e9a6ede",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/02/26 05:57:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/25/24 12:48:26 AM"
                }
              ]
            },
            {
              "id": "dbf8dddc-740c-4994-a827-f0ed354a8012",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/21/29 10:36:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/11/30 12:04:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/22/29 12:45:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/10/26 11:58:31 PM"
                }
              ]
            },
            {
              "id": "ce173f21-a5fc-4495-8246-c12e1f56b9f6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/21/25 03:16:31 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/11/28 05:14:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/21/27 02:33:33 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "5bef5e71-76f8-433e-8050-261e1768cdad",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1736,
          "address": "620 Doughty Street, Kula, North Carolina, 1885",
          "acreage": 2009,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "09/14/24 10:29:11 AM",
          "documents": [],
          "turnTime": 27,
          "bidAmount": "$3,640.91",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 18,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7967,
            "parcelNumbers": 62108739404483,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "98826cfa-abde-49a8-94a0-82c87e2f6b58",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/17/26 04:29:34 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/16/29 12:28:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/17/27 03:06:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/06/27 02:39:28 AM"
                }
              ]
            },
            {
              "id": "90a232eb-ca0d-4ed2-a947-54b8f435bbbb",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/16/28 02:40:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/07/28 05:12:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/18/24 04:53:46 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/10/27 07:43:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/28/29 07:48:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/10/26 03:04:05 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fe022210acd7a758e",
      "groupName": "Taco Bell Holdings",
      "type": "invitation",
      "data": [
        {
          "id": "3b3dcf9d-e1b5-48dc-89db-5036b6cbae39",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3723,
          "address": "440 Schenck Place, Sedley, North Dakota, 3597",
          "acreage": 5572,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/02/24 12:35:21 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 84,
          "bidAmount": "$2,606.80",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1342,
            "parcelNumbers": 83042702340443,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "dbaa6b66-02b1-441c-ace6-f1dafe97291c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/27 12:25:20 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/27 02:50:21 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/11/29 09:21:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/11/27 11:52:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/02/27 05:20:04 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/21/26 05:43:43 AM"
                }
              ]
            },
            {
              "id": "27cff1bb-21ef-446b-96ce-2e60774b62d5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/06/27 02:28:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/14/27 10:08:51 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/27 03:29:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/29 02:39:12 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/16/29 03:38:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/27/25 10:53:04 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "717fcf73-55ab-4269-8a92-7107094ba08d",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 2380,
          "address": "615 Beekman Place, Orovada, Vermont, 8857",
          "acreage": 4819,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/12/25 04:34:25 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 20,
          "bidAmount": "$3,592.30",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4820,
            "parcelNumbers": 80637261769047,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1aee0a69-1767-4b81-a484-a2fd4cae77b5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/13/28 06:47:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/16/28 09:01:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/25 05:41:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/13/28 03:27:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/10/25 03:28:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/23/25 01:21:40 PM"
                }
              ]
            },
            {
              "id": "09adb001-f3ee-4b14-ae5d-bb4d0454eb9e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/12/26 02:57:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/26/24 10:12:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/12/25 02:28:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/14/29 07:44:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/26/28 03:35:53 AM"
                }
              ]
            },
            {
              "id": "9b533159-bc74-40d2-b483-8b81e2511200",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/01/24 08:01:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/02/28 06:06:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/08/27 01:53:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/12/24 01:43:28 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "9bd32ee1-c55f-474a-a265-77e8b3ecc651",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3441,
          "address": "328 Saratoga Avenue, Cawood, Wyoming, 5531",
          "acreage": 8256,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "09/05/25 01:23:55 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 98,
          "bidAmount": "$1,776.93",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9680,
            "parcelNumbers": 57102725096450,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "6a038722-6509-4f30-b733-8818871ea699",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/20/28 12:45:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/23/27 07:23:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/25 04:53:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/12/28 06:12:18 PM"
                }
              ]
            },
            {
              "id": "5e30320e-1f8c-491e-b3d4-9362f74d16d7",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/01/27 08:51:57 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/05/25 01:51:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/11/29 07:39:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/21/29 02:48:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/02/24 06:37:37 PM"
                }
              ]
            },
            {
              "id": "01d95b8d-5379-4281-bba7-ef06b18a4d88",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/18/27 12:15:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/12/25 05:04:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/13/27 02:41:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/21/24 01:53:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/09/25 06:31:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/25/27 08:07:35 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "11730030-e18a-4fc5-b81f-af12debac96d",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2129,
          "address": "798 Franklin Street, Diaperville, Maryland, 8004",
          "acreage": 1344,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "11/22/28 03:48:08 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 41,
          "bidAmount": "$1,112.65",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7924,
            "parcelNumbers": 88241101849740,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f75c3b46-8050-4a67-a109-0a859467b113",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/21/25 03:49:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/16/29 09:21:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/07/25 05:22:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/16/26 12:10:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/11/25 04:13:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/16/30 11:35:45 AM"
                }
              ]
            },
            {
              "id": "6c08f23d-ae88-4655-b4a1-5172286d2c7c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/27/28 09:24:19 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/20/26 06:57:07 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "3412aa02-7d44-4392-9ab1-771a4acb977f",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 278,
          "address": "725 Lefferts Place, Joppa, Pennsylvania, 6162",
          "acreage": 8745,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "02/10/25 09:30:04 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$2,787.31",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5917,
            "parcelNumbers": 69545466363629,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "85c1331d-04e3-47fd-a1bb-621f529ce248",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/04/29 12:25:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/26 06:33:59 AM"
                }
              ]
            },
            {
              "id": "4900a7cb-cd92-4fd5-b63d-a7f771cf5604",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/03/29 07:52:04 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/24/28 06:52:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/19/28 08:43:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/05/25 05:22:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/02/26 12:00:27 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/29/30 09:58:40 PM"
                }
              ]
            },
            {
              "id": "60372f1c-e442-4869-a2f4-d5bb23245259",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/31/25 10:38:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/01/28 10:28:56 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/07/26 05:20:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/09/27 04:03:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/25 12:16:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/28 02:43:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/16/25 06:56:56 AM"
                }
              ]
            },
            {
              "id": "ed1b9c5b-86cb-4ac0-bf67-75087d6f0324",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/17/25 02:13:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/18/25 07:57:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/30 10:06:59 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/06/25 06:24:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/29 12:08:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/27 12:12:56 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "4d0f1888-307f-4d7e-96b5-907f47e3f80c",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3030,
          "address": "199 Rugby Road, Chical, New Mexico, 7237",
          "acreage": 3187,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/24/25 08:49:18 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 94,
          "bidAmount": "$3,755.33",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1845,
            "parcelNumbers": 81951397033136,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a3f06732-82b9-4e91-aab2-cf79e9d46fb8",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/19/29 07:23:50 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/09/29 05:52:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/20/25 06:01:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/14/27 08:37:54 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/15/27 05:24:11 PM"
                }
              ]
            },
            {
              "id": "3321db1a-f9d2-40b5-997c-5c32eeb3f7a3",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/24 08:43:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/04/27 01:12:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/10/24 10:04:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/26/27 10:07:44 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/31/24 10:24:59 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/12/27 08:17:31 AM"
                }
              ]
            },
            {
              "id": "1d8fc1b0-3192-43cb-a7c2-0a24ffa8aa9a",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/29 09:25:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/06/27 06:51:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/31/26 05:39:06 AM"
                }
              ]
            },
            {
              "id": "002023a6-4295-4721-9e4b-ea2d9b8d8bce",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/16/25 11:12:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/28/28 06:15:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/30/24 01:20:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/19/26 01:13:36 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "b9d50b8b-9161-447f-981a-442fa5e96d0d",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2653,
          "address": "297 Grand Street, Movico, Arizona, 7545",
          "acreage": 4743,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "07/27/29 06:34:51 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 14,
          "bidAmount": "$1,085.60",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2367,
            "parcelNumbers": 41206469789499,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1ab8ba93-f0b0-4626-8d87-b29a3250980a",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/27 10:23:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/31/27 06:28:31 AM"
                }
              ]
            },
            {
              "id": "876701f9-4e9b-45d9-9d7a-ab59537a31b1",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/01/25 02:43:31 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/28 11:45:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/23/29 08:08:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/11/26 05:47:18 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/10/28 12:20:33 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/19/28 02:13:54 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f6c11031959f32218",
      "groupName": "McDonalds McMoney",
      "type": "active",
      "data": [
        {
          "id": "f7f066bb-970e-4637-9c36-3c1b1d5fefb5",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2592,
          "address": "106 Chestnut Avenue, Retsof, Texas, 3290",
          "acreage": 3599,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/24/27 12:16:58 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 95,
          "bidAmount": "$1,456.46",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6693,
            "parcelNumbers": 85779391167556,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "cb929d64-0012-40c5-a319-45a0a9cdcdb1",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/15/27 03:29:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/27 01:49:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/22/27 02:11:45 PM"
                }
              ]
            },
            {
              "id": "979baf80-249a-4fd5-b2b4-71384dc78957",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/17/27 10:02:56 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/01/29 09:12:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/21/25 03:11:24 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/22/28 07:56:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/25 09:34:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/17/29 11:59:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/12/29 02:43:18 PM"
                }
              ]
            },
            {
              "id": "7962478f-a19c-4d57-873f-53fbad505e6c",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/14/25 09:29:38 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/25/26 04:38:41 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/11/29 07:31:37 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "61283bba-5828-4d2b-a68d-452b55b51163",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1495,
          "address": "485 Woodpoint Road, Ruffin, Michigan, 5823",
          "acreage": 5583,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "04/12/28 06:16:00 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 15,
          "bidAmount": "$1,627.16",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 20,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5033,
            "parcelNumbers": 94438959598014,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2f6796a4-e3de-4ec8-8a80-a26f14f22356",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/21/27 05:08:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/14/29 04:22:30 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/03/27 11:41:04 AM"
                }
              ]
            },
            {
              "id": "de1311de-4f16-4d30-89cd-c6ab8f978fea",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/02/25 03:45:24 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/14/29 02:37:12 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "083d7596-c3d9-4da9-a4ae-90db0d9a899a",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 532,
          "address": "162 Holly Street, Hayes, New Hampshire, 9328",
          "acreage": 9292,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/11/26 06:23:32 AM",
          "documents": [],
          "turnTime": 65,
          "bidAmount": "$1,887.65",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2930,
            "parcelNumbers": 20566606539190,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "c923e7d9-f28f-48fa-9439-428f838d1272",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/27/28 07:24:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/19/27 07:28:05 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/01/26 08:45:37 AM"
                }
              ]
            },
            {
              "id": "04bda457-547c-4da0-b277-f1ef24e890f4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/26 08:34:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/03/27 05:46:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/16/26 09:55:42 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "2df00aae-d992-4ed2-89a6-4f78e8632e30",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1123,
          "address": "878 Bleecker Street, Greenbush, Mississippi, 571",
          "acreage": 8762,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/07/25 09:22:25 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 9,
          "bidAmount": "$3,880.71",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5412,
            "parcelNumbers": 57245698083991,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "bce6f22a-8242-4b1e-87b9-1d9712189fcd",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/28 02:42:46 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/10/26 06:28:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/04/29 10:14:37 AM"
                }
              ]
            },
            {
              "id": "78c29ca4-1e81-47a3-b412-af0cbfb2d7e7",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/08/26 06:24:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/22/24 07:12:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/02/25 05:32:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/25/28 11:01:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/25 09:13:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/12/25 09:38:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/04/26 08:03:07 PM"
                }
              ]
            },
            {
              "id": "dc0e1385-2ad7-44be-8413-c70b9690f460",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/21/28 08:30:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/01/27 11:40:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/08/28 07:47:43 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/11/25 07:56:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/19/29 11:17:53 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/11/27 11:17:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/31/24 02:05:02 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "f78a53ae-226e-4ff2-8717-04ec1ce0694e",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 922,
          "address": "668 Chestnut Street, Nescatunga, Connecticut, 7416",
          "acreage": 8130,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "07/01/29 08:24:21 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 51,
          "bidAmount": "$3,268.74",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6669,
            "parcelNumbers": 83295168000704,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d9ddbb75-cf47-41c2-af73-98f7698b8500",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/29 04:35:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/03/26 02:09:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/06/29 01:19:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/11/28 09:24:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/24/25 10:18:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/20/26 01:19:08 AM"
                }
              ]
            },
            {
              "id": "da29585a-5f82-440a-94f5-4733afab8072",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/10/30 11:25:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/21/27 02:20:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/04/26 01:38:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/27/27 06:17:52 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/07/28 08:51:52 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "df5fef65-1078-443e-8d92-0ab1fca6b304",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1901,
          "address": "858 Jaffray Street, Gerton, Virgin Islands, 898",
          "acreage": 1898,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "05/05/27 02:42:19 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$2,445.23",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6759,
            "parcelNumbers": 10406977041951,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "738076e5-e43d-4a47-a5fa-092be358c118",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/18/28 12:38:20 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/29 06:17:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/14/25 11:10:17 AM"
                }
              ]
            },
            {
              "id": "d5b79841-b4c5-4332-a015-60bed8d2c11a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/06/29 08:25:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/02/28 10:24:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/25/25 05:59:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/20/25 08:34:30 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f4f3e1ebc68a24c67",
      "groupName": "Burger King Coffers",
      "type": "proposal",
      "data": [
        {
          "id": "dd1713cd-2b0b-4138-9a7c-c119d2e413f4",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 2386,
          "address": "474 Chase Court, Savannah, West Virginia, 1761",
          "acreage": 4836,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "03/27/27 04:31:24 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 37,
          "bidAmount": "$2,716.20",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 11,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7114,
            "parcelNumbers": 87063598829868,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e7d14fb8-08f0-4a78-b10f-1b0c97f36c19",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/04/24 06:13:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/03/26 08:15:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/26/29 01:04:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/08/27 07:46:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/28/24 01:59:56 PM"
                }
              ]
            },
            {
              "id": "54e5edfe-9877-4b42-ad0c-7c4ac3f9c89f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/24/28 06:24:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/09/25 05:42:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/07/26 03:36:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/17/27 05:27:30 PM"
                }
              ]
            },
            {
              "id": "89292dfd-5625-481c-aaab-8424b6d3f571",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/09/29 08:13:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/19/28 10:46:49 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/27/29 04:22:52 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/31/28 10:04:06 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "6f484e7b-0697-4a07-a554-a68a90925300",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1640,
          "address": "448 Voorhies Avenue, Roulette, Oregon, 394",
          "acreage": 8974,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "03/22/29 08:48:58 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 32,
          "bidAmount": "$3,289.27",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8565,
            "parcelNumbers": 52171042143078,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "7eeebdd8-7b40-4c72-b86b-b7694962e2a3",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/27/28 08:19:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/12/27 12:46:47 AM"
                }
              ]
            },
            {
              "id": "e797e023-5e8d-4d15-a77a-c253b1b9a1ab",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/19/29 01:56:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/07/25 12:50:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/29/26 09:28:56 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/12/26 06:48:10 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/08/27 06:52:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/26/28 10:49:47 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/08/29 02:34:54 AM"
                }
              ]
            },
            {
              "id": "6a5c1ca2-3fb4-4273-9865-d3fc255cfb89",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/23/27 04:37:41 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/30/26 06:14:13 PM"
                }
              ]
            },
            {
              "id": "35a7cf58-3707-4f08-89a8-71d92ff76dfb",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/05/27 11:38:09 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/14/29 02:53:38 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "7ab69c3f-b817-444b-9d3e-0cd94dd3a94d",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2018,
          "address": "918 Moffat Street, Crisman, Utah, 3653",
          "acreage": 5829,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "08/01/25 07:56:39 AM",
          "documents": [],
          "turnTime": 48,
          "bidAmount": "$3,034.08",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4423,
            "parcelNumbers": 76139162173511,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2bc0b86d-7beb-4912-a2fc-0d42b08ff3b4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/28/28 05:43:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/23/28 09:22:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/08/27 05:59:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/13/26 04:19:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/21/27 05:18:44 PM"
                }
              ]
            },
            {
              "id": "2c54b7e4-4b6a-4ac8-a897-7db7e56f8a0a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/24 11:13:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/28/28 01:57:33 AM"
                }
              ]
            },
            {
              "id": "787d39ee-a612-406a-a753-7a94b6d19e98",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/27 01:01:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/03/27 06:10:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/20/28 02:24:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/29 10:09:09 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/03/27 12:58:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/19/27 01:13:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/26/29 07:37:55 AM"
                }
              ]
            },
            {
              "id": "d279371a-f346-4466-b3e5-82c41fa319ac",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/18/28 07:10:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/24/27 05:37:16 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "2794f34b-56b4-41ea-9828-4cf40185895c",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3792,
          "address": "956 Independence Avenue, Kenvil, American Samoa, 8104",
          "acreage": 5046,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "07/07/28 04:15:05 AM",
          "documents": [],
          "turnTime": 88,
          "bidAmount": "$2,236.38",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6372,
            "parcelNumbers": 41658797445534,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e2e64cfb-2b80-4fa3-9817-a899c9492403",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/04/25 07:19:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/15/26 10:52:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/30/25 07:13:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/05/26 09:54:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/17/29 02:35:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/24/26 06:30:32 PM"
                }
              ]
            },
            {
              "id": "adb69a9d-ad92-4b2e-b57f-1105b3096519",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/24 06:12:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/17/27 02:57:52 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/26 10:54:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/20/27 06:35:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/28 03:10:35 PM"
                }
              ]
            },
            {
              "id": "b3fdfe70-325c-464b-9a42-eade80daade6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/27 04:09:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/05/27 07:50:31 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/01/28 11:24:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/10/25 11:07:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/27/29 03:27:23 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/05/29 03:27:01 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/30/25 10:49:16 AM"
                }
              ]
            },
            {
              "id": "e3280542-7c36-41ee-9012-3e95896f0110",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/08/26 02:54:37 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/24 01:38:42 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "370d5c00-06c3-459b-ac64-a85448c74f93",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3077,
          "address": "644 Roosevelt Court, Cucumber, Nevada, 9678",
          "acreage": 5445,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "03/22/27 06:45:45 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 69,
          "bidAmount": "$3,091.64",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3889,
            "parcelNumbers": 37087019407354,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a72bb4e8-f858-45ac-af9f-bace4f2e3a8d",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/16/28 05:10:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/30/27 05:36:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/06/28 09:37:03 AM"
                }
              ]
            },
            {
              "id": "42c251b5-9391-472d-8473-980f2f39f2ba",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/10/28 04:26:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/07/25 01:44:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/02/29 04:49:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/15/25 06:49:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/12/28 05:54:04 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/28 08:48:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/04/24 06:26:31 AM"
                }
              ]
            },
            {
              "id": "5775823a-2d73-4446-8d36-a6a90ca0f61d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/18/25 05:20:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/28/27 02:05:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/12/29 11:00:59 AM"
                }
              ]
            },
            {
              "id": "eb158b2a-80a5-48d0-99e7-e9d6802a21dc",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/21/28 02:50:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/25/28 03:39:08 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f027216ef9c994607",
      "groupName": "Dominos Deposits",
      "type": "proposal",
      "data": [
        {
          "id": "da74d0f7-1ed8-49b9-9c73-bcfc1705d3cc",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3662,
          "address": "102 Conduit Boulevard, Roosevelt, Montana, 3726",
          "acreage": 3554,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "07/08/25 04:18:27 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 91,
          "bidAmount": "$2,951.53",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3946,
            "parcelNumbers": 76408353657040,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1dd1ae33-9693-49a0-8d07-a184afabf37f",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/21/26 04:17:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/27 10:08:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/11/26 04:55:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/12/29 11:10:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/06/29 02:53:35 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/13/28 08:08:37 AM"
                }
              ]
            },
            {
              "id": "09438903-f94b-4b70-8a86-42e12349020d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/28/29 10:02:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/03/27 11:48:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/30/28 12:05:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/15/27 07:09:56 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "0df1017e-d5d8-425e-8efc-131cfdc5ca3a",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 2528,
          "address": "940 Atlantic Avenue, Tuskahoma, California, 6553",
          "acreage": 2800,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "11/22/27 09:00:34 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 31,
          "bidAmount": "$3,833.34",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 17,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6581,
            "parcelNumbers": 82082796753319,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "25390904-afe4-4860-a626-a5112a03cc7a",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/25 11:31:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/29/26 05:21:22 AM"
                }
              ]
            },
            {
              "id": "135f93b9-5cc0-42d9-b23b-113d7d763c40",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/30 03:57:08 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/27 05:16:24 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "2c01e0ce-c052-4bc4-b21b-1b0dc3f322e8",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 2669,
          "address": "809 Canton Court, Villarreal, Georgia, 4484",
          "acreage": 9187,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "06/08/29 07:14:04 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 20,
          "bidAmount": "$2,197.80",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4248,
            "parcelNumbers": 98702142948312,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "3dfe2c28-efee-4fe4-9612-ccf43f3ff1e2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/14/26 03:15:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/06/25 08:08:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/22/29 10:26:18 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/26 01:35:50 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/15/28 02:40:28 PM"
                }
              ]
            },
            {
              "id": "fad7c3b4-5e47-480d-9624-a98469bbfa2c",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/21/27 07:37:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/29 04:24:48 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/30/25 03:08:57 AM"
                }
              ]
            },
            {
              "id": "dcf1c006-31a0-4d73-9c4b-d910dc99365b",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/14/26 12:37:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/11/29 05:51:03 PM"
                }
              ]
            },
            {
              "id": "38ea2083-8c24-444c-b341-cea0473a30be",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/26/26 03:49:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/12/25 10:47:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/25 02:46:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/25/25 11:21:59 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "eeeb0b92-9a97-4f96-ae16-fc9ed6dee4bd",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1827,
          "address": "281 Seabring Street, Westboro, Virginia, 6444",
          "acreage": 9693,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "06/17/27 11:20:34 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 86,
          "bidAmount": "$3,311.30",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 11,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3129,
            "parcelNumbers": 48465389699827,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "ff14ce73-6c57-4719-a1bb-08bf8384d307",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/21/26 01:09:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/22/24 10:25:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/18/25 02:26:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/26/28 05:04:59 AM"
                }
              ]
            },
            {
              "id": "c703d20c-effd-462b-bff0-b8f5a1d3624a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/05/29 06:50:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/16/29 12:33:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/28 04:00:06 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/20/27 09:12:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/14/26 01:35:23 PM"
                }
              ]
            },
            {
              "id": "6fcd498d-d063-43c2-a2f9-25c35a9c55f6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/13/28 06:31:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/11/26 01:39:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/26/26 12:57:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/30/27 05:08:36 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/02/28 02:42:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/20/26 11:39:12 AM"
                }
              ]
            },
            {
              "id": "388d0a4a-ccfb-4753-b46c-b2112d34de21",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/25/28 06:02:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/17/25 12:11:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/23/24 09:34:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/03/29 08:34:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/02/28 09:03:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/20/26 09:19:20 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "3958c5c4-8e43-4878-9a8b-b8cc21f1b22f",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2686,
          "address": "753 Manor Court, Sattley, Oklahoma, 6117",
          "acreage": 4361,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "06/09/26 05:33:16 PM",
          "documents": [],
          "turnTime": 37,
          "bidAmount": "$2,413.63",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3344,
            "parcelNumbers": 95634585374622,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "6e861a11-7807-4a4d-b908-7e391de8ea4f",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/03/29 10:19:24 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/23/27 01:25:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/15/29 11:40:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/12/29 11:59:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/05/25 07:10:19 AM"
                }
              ]
            },
            {
              "id": "4eb1e034-9ec1-45be-9e42-a708e629c81c",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/04/26 06:55:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/05/26 08:45:18 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/26/26 11:57:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/14/26 04:01:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/18/26 06:31:14 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/16/27 08:38:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/23/25 02:03:18 PM"
                }
              ]
            },
            {
              "id": "2a2777c0-2b60-43c0-a09a-c3d22bb09598",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/23/29 04:44:18 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/13/29 06:20:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/25/26 03:44:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/06/25 07:55:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/26 01:58:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/30/28 01:07:09 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/27/28 10:18:11 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f1ca782c7642d5ab1",
      "groupName": "Taco Bell Holdings",
      "type": "invitation",
      "data": [
        {
          "id": "f1ec10ac-f716-4d37-a944-8bf113b8583f",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1367,
          "address": "546 Folsom Place, Roberts, Massachusetts, 7596",
          "acreage": 3850,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/13/24 11:32:22 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 2,
          "bidAmount": "$1,727.87",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9426,
            "parcelNumbers": 47683546801825,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1c06e0f7-1611-405d-94ac-d21b5a2296a6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/26/27 01:17:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/24/26 09:20:16 AM"
                }
              ]
            },
            {
              "id": "6a2b388c-425d-423f-86f4-6dc8519a4b01",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/04/29 01:28:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/27/28 12:40:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/26 03:29:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/23/26 07:34:00 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/31/29 01:12:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/29/29 07:49:41 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/24/26 06:40:38 PM"
                }
              ]
            },
            {
              "id": "90a79f16-7815-41fe-816b-b97264b36539",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/04/27 05:28:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/28/27 09:42:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/21/25 09:37:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/17/28 12:26:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/05/29 10:13:52 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/22/26 02:27:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/25 04:58:59 PM"
                }
              ]
            },
            {
              "id": "6cb4843c-95eb-4e5a-8f38-8455acc322dc",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/04/29 12:02:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/29 02:50:54 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/08/28 01:33:00 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/21/26 11:28:38 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "bb7e8ad6-11e2-43aa-9411-942c2b22add9",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1833,
          "address": "744 Lafayette Avenue, Tryon, Alabama, 8728",
          "acreage": 3068,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "12/24/27 09:46:04 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 59,
          "bidAmount": "$2,464.06",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 20,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4804,
            "parcelNumbers": 23512912330840,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f77256ae-4f0d-435e-ab4b-9db30321cfd0",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/05/26 04:31:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/06/29 04:29:45 AM"
                }
              ]
            },
            {
              "id": "c4d0934e-531d-473b-a850-3353709f3295",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/10/28 03:39:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/11/27 09:20:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/15/27 08:44:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/23/25 03:23:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/04/25 11:58:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/02/25 11:15:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/12/27 06:06:45 AM"
                }
              ]
            },
            {
              "id": "710d45d2-de88-4a77-aeae-c58a331bfaf1",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/27 09:45:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/27/24 05:57:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/03/27 06:37:53 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/13/25 04:27:57 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/08/26 05:42:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/14/29 05:02:28 PM"
                }
              ]
            },
            {
              "id": "6a7d9359-7c72-4df9-87d5-dce2bd1b7a39",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/15/27 08:56:59 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/12/25 08:29:16 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "e23bf9c6-ad89-4471-9abc-4b61fb97a8ba",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 161,
          "address": "227 Pierrepont Street, Chumuckla, Puerto Rico, 8959",
          "acreage": 2345,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "12/11/24 05:56:00 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 63,
          "bidAmount": "$2,724.73",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2636,
            "parcelNumbers": 95540965133912,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "9491f6ca-58fe-4d3f-bb26-4e87b9add162",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/15/25 08:17:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/30/29 07:13:57 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/05/27 07:31:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/26 07:57:26 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/08/25 05:01:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/18/29 01:14:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/18/26 10:32:04 PM"
                }
              ]
            },
            {
              "id": "58ddb560-cf82-45f6-af08-3b660fbb6792",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/31/26 07:57:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/05/29 10:46:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/28/24 09:01:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/26 12:01:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/25/30 09:16:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/29 12:29:48 PM"
                }
              ]
            },
            {
              "id": "295a2d0a-ebc6-4fc1-a8aa-eee7b5beb01d",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/22/26 05:14:19 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/22/28 02:55:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/06/27 11:04:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/10/26 12:59:04 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/04/26 07:23:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/18/27 05:11:49 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/16/25 08:02:10 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "908988b8-2c65-4a2d-a8db-c0b6b8decbe1",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3336,
          "address": "965 Church Avenue, Beason, Kansas, 4119",
          "acreage": 2898,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "08/25/27 11:27:31 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 38,
          "bidAmount": "$2,085.85",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3666,
            "parcelNumbers": 25010060154681,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "5de4798d-a092-42fd-a3a9-f3d90664a1bf",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/14/28 10:13:19 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/24 02:39:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/03/27 06:33:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/24/24 07:35:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/02/27 01:14:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/30/24 04:35:37 PM"
                }
              ]
            },
            {
              "id": "1de09b91-d331-468b-be5e-f71f4842fe5a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/06/25 01:20:07 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/02/25 01:44:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/15/26 06:19:19 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/11/29 08:13:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/14/25 09:54:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/23/28 08:26:55 AM"
                }
              ]
            },
            {
              "id": "c1ff4c25-11b8-45ed-8bb9-05c6d3bb9ca5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/19/25 04:14:57 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/27/26 01:17:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/09/25 07:20:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/02/27 02:28:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/31/27 02:20:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/04/27 03:30:13 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "716b133a-b908-412d-9be8-75f3abe16186",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1143,
          "address": "501 Brooklyn Avenue, Driftwood, Nebraska, 6436",
          "acreage": 6814,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "06/18/25 06:30:11 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 80,
          "bidAmount": "$1,278.94",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1353,
            "parcelNumbers": 60441238798951,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "377c9fd2-c8f7-4921-8cc0-711f3fd2bdd8",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/07/27 09:17:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/10/24 11:17:00 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/01/25 04:02:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/16/28 09:27:32 PM"
                }
              ]
            },
            {
              "id": "c37db233-bfdb-4879-843c-93fd0fcd9471",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/11/25 07:30:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/11/26 01:53:37 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/26 04:00:36 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/22/28 09:31:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/08/28 02:01:53 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/28 06:05:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/23/26 01:43:49 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "f4b0b675-7b69-4341-bebb-53c0c618c334",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 966,
          "address": "775 Hawthorne Street, Fillmore, Idaho, 3959",
          "acreage": 9797,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "01/15/27 09:42:39 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 47,
          "bidAmount": "$2,584.60",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 20,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1957,
            "parcelNumbers": 53571847843975,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1f7a9483-2df4-4647-a887-00c7957789cd",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/20/27 09:45:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/19/26 03:15:06 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/01/26 04:29:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/17/24 12:39:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/01/28 06:51:03 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/16/26 10:21:16 PM"
                }
              ]
            },
            {
              "id": "5db7a74d-f8fd-47dd-ba3c-6089691bb034",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/29/28 09:49:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/31/29 10:03:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/03/25 12:40:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/03/27 03:24:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/03/25 11:50:04 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/08/25 10:46:14 PM"
                }
              ]
            },
            {
              "id": "04482f4f-34d6-4a32-8424-c6a933c19039",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/16/24 11:52:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/12/26 02:15:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/17/27 08:33:48 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f1a8956f6a539e097",
      "groupName": "Dominos Deposits",
      "type": "proposal",
      "data": [
        {
          "id": "dfa1f31b-ec72-4876-85a2-6291efa3e3b0",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3406,
          "address": "893 George Street, Falmouth, South Carolina, 9116",
          "acreage": 8299,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "09/27/29 02:01:45 PM",
          "documents": [],
          "turnTime": 48,
          "bidAmount": "$2,336.35",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9680,
            "parcelNumbers": 14947378293807,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "fd670154-080f-4df9-bda8-254a19305732",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/20/29 12:24:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/14/27 12:05:59 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/02/29 05:34:20 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/03/27 05:25:39 AM"
                }
              ]
            },
            {
              "id": "1837eea8-7467-4968-ad9a-6b7bf1bdf86c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/16/26 06:51:10 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/15/28 11:41:24 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/27/27 08:35:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/26/27 06:35:15 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "3cb4cb49-7c39-4f8a-bc21-6f32450df241",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3500,
          "address": "150 Seagate Avenue, Summertown, Ohio, 9476",
          "acreage": 6123,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "09/04/26 12:39:13 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 23,
          "bidAmount": "$3,981.97",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5129,
            "parcelNumbers": 24964252763044,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f6e6a6d7-07a9-4492-9ba5-ec9096f4aaf1",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/09/26 10:15:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/30/24 01:17:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/22/29 01:29:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/23/27 01:05:59 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/22/25 01:36:03 AM"
                }
              ]
            },
            {
              "id": "31514d93-215e-4a31-94bf-e585405145ee",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/12/27 08:18:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/29/26 08:40:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/20/27 09:46:29 PM"
                }
              ]
            },
            {
              "id": "4bd2d548-df81-4ac0-a2a3-0d5b94ef7b9d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/23/28 04:07:31 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/25/29 06:07:59 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/12/24 11:35:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/13/27 06:47:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/04/26 04:41:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/20/25 07:31:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/05/29 10:22:45 AM"
                }
              ]
            },
            {
              "id": "3ee6a002-918c-4451-ba07-c8c892388bc4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/27/29 06:32:54 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/17/25 10:05:27 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/27 08:33:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/24/25 03:25:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/09/27 05:47:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/31/26 01:20:39 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "894a865b-4e37-47ce-87ce-1eadd195e7a1",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1601,
          "address": "905 Wolcott Street, Hardyville, Guam, 2813",
          "acreage": 4914,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "02/17/26 11:53:13 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 43,
          "bidAmount": "$3,337.92",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6899,
            "parcelNumbers": 55728156553217,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "adaf6b0e-8cdf-4527-af34-4f8ae4065f94",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/04/25 06:26:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/08/27 05:51:48 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/12/26 05:26:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/14/28 08:30:55 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/17/26 12:27:47 AM"
                }
              ]
            },
            {
              "id": "5fbba697-1848-40ec-badc-fabf74122fbd",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/27 02:10:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/20/26 06:53:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/13/24 07:32:55 PM"
                }
              ]
            },
            {
              "id": "8f080aa5-a7af-409d-8a13-4f5f671bdaba",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/02/28 08:23:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/29 07:22:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/06/26 04:10:28 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "6d49e0db-ffb4-48d4-b19f-9869f164600f",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1555,
          "address": "811 Dodworth Street, Zarephath, Illinois, 5919",
          "acreage": 8171,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "06/22/26 01:44:25 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 94,
          "bidAmount": "$1,891.49",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6086,
            "parcelNumbers": 28974946504434,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2e3e6413-c5e1-4847-8ce1-d7f9c225f1f9",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/24/28 07:13:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/08/26 10:51:13 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/24/29 08:52:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/26/29 07:04:51 PM"
                }
              ]
            },
            {
              "id": "9fe904d9-b495-4134-b834-42c0184688a3",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/12/28 01:24:09 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/23/26 05:33:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/25/27 08:26:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/03/24 06:30:24 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/18/26 11:36:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/26/26 10:32:58 PM"
                }
              ]
            },
            {
              "id": "96943031-ae69-4aed-b35f-c7256fc01fee",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/09/26 07:57:22 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/28 11:16:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/16/27 06:50:37 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/08/29 04:02:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/02/27 01:47:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/24/26 02:57:16 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "dbc3967b-f9cc-47fa-8e64-2a9a8e4e586d",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3513,
          "address": "712 Baltic Street, Saticoy, New York, 6393",
          "acreage": 2503,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "01/26/28 03:57:51 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 86,
          "bidAmount": "$3,860.00",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5452,
            "parcelNumbers": 96560428701499,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "7a6c5722-7a66-450b-b4d3-289ed4335e72",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/12/27 11:02:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/21/25 04:59:11 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/29/29 02:04:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/29 04:50:39 AM"
                }
              ]
            },
            {
              "id": "99f075b1-e8fc-461e-9e11-2f2c38aa76ad",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/14/27 04:00:41 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/18/29 03:17:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/10/25 04:14:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/24/29 03:12:42 AM"
                }
              ]
            },
            {
              "id": "459aad96-5ca2-4516-a367-46ffdb612ca7",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/30/25 06:14:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/25/27 10:46:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/13/25 02:04:14 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/30/24 09:17:22 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fe87861f4aebba490",
      "groupName": "Burger King Coffers",
      "type": "active",
      "data": [
        {
          "id": "cbba0eaa-2217-45df-9111-4aa6f4542f05",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3225,
          "address": "598 Johnson Avenue, Robinson, Wisconsin, 3191",
          "acreage": 6338,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "12/23/24 12:56:36 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 63,
          "bidAmount": "$2,573.54",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7081,
            "parcelNumbers": 36814298896796,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1b7d01c2-b174-4911-a4ac-953e2ce5f34d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/01/28 10:47:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/26 11:56:54 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/27/28 05:12:25 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/23/26 01:21:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/08/27 12:37:51 AM"
                }
              ]
            },
            {
              "id": "ffe023d6-8166-4d1f-8671-3bca97919070",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/05/27 01:09:51 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/09/28 03:24:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/23/29 10:59:26 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/08/26 04:34:05 AM"
                }
              ]
            },
            {
              "id": "baefe22d-364d-4080-97b6-dd02264fc972",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/09/27 05:00:03 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/28 06:42:21 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "a95f48d1-6df2-4721-8d91-38f342fd2d45",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 769,
          "address": "478 Newel Street, Dale, District Of Columbia, 3945",
          "acreage": 9177,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "07/29/27 05:38:33 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 31,
          "bidAmount": "$3,805.71",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9586,
            "parcelNumbers": 47939804029515,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e44b4145-bb03-4375-8b3f-72727025abe5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/26/29 08:01:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/26 02:56:14 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/21/28 10:34:11 AM"
                }
              ]
            },
            {
              "id": "6b30be86-3c6a-46c6-b621-d81de03f5fb9",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/20/24 05:24:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/05/28 11:49:02 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/31/29 04:00:49 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/27 08:57:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/30/27 11:57:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/17/25 11:56:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/12/24 02:02:05 AM"
                }
              ]
            },
            {
              "id": "39648926-984f-409e-a5e3-6bbd1b2a2361",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/01/26 03:12:48 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/02/25 10:35:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/03/29 02:55:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/01/27 10:26:50 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/29/29 03:09:31 AM"
                }
              ]
            },
            {
              "id": "d6a34e32-330e-4ae8-94d1-a1beddc94181",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/23/25 12:26:20 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/20/26 08:08:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/18/29 02:24:13 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "4cf0652b-8014-40b7-a698-ec1dfc8de231",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3517,
          "address": "826 Conover Street, Curtice, Marshall Islands, 8936",
          "acreage": 9976,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "06/27/26 08:01:01 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 75,
          "bidAmount": "$1,508.73",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 4,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4813,
            "parcelNumbers": 34968546886091,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "097d9db9-2f7b-4246-afef-1dfe7f5f8e31",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/14/26 07:42:29 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/25 07:54:38 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/25 03:34:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/25/29 02:57:33 AM"
                }
              ]
            },
            {
              "id": "0919849b-ba37-42b6-9413-740a3fbe077c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/26 01:07:44 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/13/25 10:41:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/10/26 12:40:33 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/06/25 03:44:40 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/20/29 07:10:19 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/24/28 09:40:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/08/28 09:04:48 PM"
                }
              ]
            },
            {
              "id": "62a63930-6b62-42e3-b043-49737b473f74",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/26 10:36:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/29/26 12:36:57 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/01/28 02:34:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/07/28 12:24:41 AM"
                }
              ]
            },
            {
              "id": "f9d3b8bd-0032-4634-869c-bb7907079b41",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/24/28 02:21:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/16/29 03:46:45 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ead6aa46-8221-4af1-a560-55d280589c42",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1573,
          "address": "752 Bay Parkway, Idamay, Louisiana, 7027",
          "acreage": 4322,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "01/11/26 09:31:16 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 70,
          "bidAmount": "$1,600.43",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4103,
            "parcelNumbers": 65882957750380,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "19b0742a-7a0f-4b98-bbce-a6b8dbd125ad",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/24/24 02:50:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/13/25 10:39:54 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/27/29 06:10:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/16/28 03:16:05 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/22/29 04:25:00 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/07/27 01:15:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/23/28 12:59:09 PM"
                }
              ]
            },
            {
              "id": "92331aee-f4a5-41d5-bd25-2a6d93ff1463",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/23/25 03:43:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/20/25 08:13:29 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/04/27 04:35:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/08/27 05:48:20 AM"
                }
              ]
            },
            {
              "id": "e2a3bd05-e0d0-44aa-ab18-fb659d514053",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/19/26 08:56:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/27/28 07:54:00 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/10/25 11:54:00 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "1b24535d-8da1-414f-9dfb-a98b98cb222a",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 1505,
          "address": "487 Kansas Place, Nile, Iowa, 3046",
          "acreage": 6643,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "12/25/24 12:30:44 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 11,
          "bidAmount": "$3,173.61",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2417,
            "parcelNumbers": 49272645964137,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "128e7f40-0ccd-44d6-91e3-ca3f3aad5faf",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/10/28 11:21:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/11/28 10:48:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/18/29 11:37:52 AM"
                }
              ]
            },
            {
              "id": "1295d5ae-2e7c-4221-9204-4e1c71eb00a2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/03/29 04:54:22 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/03/26 12:03:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/26/25 12:35:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/09/26 06:51:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/04/28 08:06:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/25/29 03:28:43 AM"
                }
              ]
            },
            {
              "id": "a6f97385-0c9b-4f24-8931-aa9a744905b3",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/01/26 08:27:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/09/29 04:25:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/10/28 12:27:16 AM"
                }
              ]
            },
            {
              "id": "9ca9f5d3-6ebf-4116-ab95-792f292d702e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/10/29 05:12:25 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/19/27 08:10:11 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "5636e3cc-7824-4761-a0ea-0d3519ade1c4",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1715,
          "address": "637 Tilden Avenue, Farmington, Alaska, 7189",
          "acreage": 5762,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "08/23/29 07:44:45 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 88,
          "bidAmount": "$3,765.24",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1563,
            "parcelNumbers": 15553234377928,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "b3a5de57-f163-4f09-a252-102f3a36cfd1",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/22/29 04:16:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/31/28 03:34:19 PM"
                }
              ]
            },
            {
              "id": "a85fa89a-cf3e-4a08-8375-10d259f58b56",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/29 01:39:49 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/21/27 10:39:20 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/13/27 11:24:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/27 09:26:01 AM"
                }
              ]
            },
            {
              "id": "97341758-089b-4b4b-8939-869376d1ef21",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/22/26 06:57:31 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/20/27 06:13:37 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "c4fa6478-829d-462e-91bb-1f9bacbb5af9",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2429,
          "address": "427 Lafayette Walk, Mapletown, Federated States Of Micronesia, 2523",
          "acreage": 8551,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "02/23/28 08:32:40 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 76,
          "bidAmount": "$2,380.20",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 18,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7844,
            "parcelNumbers": 41894645962247,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "772939a7-e685-4b74-b487-aa663335bef5",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/19/29 10:06:56 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/28/28 01:25:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/26/29 01:33:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/17/27 07:01:28 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/31/25 09:49:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/30/25 03:28:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/26 05:42:24 AM"
                }
              ]
            },
            {
              "id": "0dab58b1-5489-4449-8a03-81b660d58e82",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/07/25 01:46:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/02/25 05:15:08 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/09/24 10:38:31 PM"
                }
              ]
            },
            {
              "id": "fe0fc1b5-401c-4939-8b11-26d5095fe5f4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/23/25 10:47:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/27/26 06:05:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/29/29 10:15:30 AM"
                }
              ]
            },
            {
              "id": "f2c7b98f-26ba-480c-8799-9da5d3a2d00e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/04/24 09:23:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/16/27 09:42:04 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f506ab70c46bb0401",
      "groupName": "Dominos Deposits",
      "type": "invitation",
      "data": [
        {
          "id": "6784da37-42e6-4804-a930-4b7093147096",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 2146,
          "address": "643 Thames Street, Baden, Rhode Island, 2919",
          "acreage": 2047,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/01/26 01:08:13 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 42,
          "bidAmount": "$3,595.51",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1058,
            "parcelNumbers": 18542784379106,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "4ab4b9c4-dcd7-4195-8c81-2308ac967c9e",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/10/28 03:38:00 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/27/26 12:00:43 AM"
                }
              ]
            },
            {
              "id": "3ec4b7d0-d7fe-4648-b5cb-8f53ff894acf",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/15/25 04:32:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/27 01:47:32 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/08/27 11:59:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/26 03:50:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/25/26 07:38:28 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/30 09:14:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/27/27 08:06:42 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ae48baa3-928a-41d6-bc8f-f889a4280dcd",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1172,
          "address": "945 Lawton Street, Callaghan, Missouri, 7199",
          "acreage": 9863,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/13/29 07:18:54 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 36,
          "bidAmount": "$2,630.32",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5009,
            "parcelNumbers": 49724450661347,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e9f865a6-9a04-4915-9a18-8aaece8bf916",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/05/25 09:21:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/04/28 05:37:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/24/27 06:25:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/06/28 01:04:52 PM"
                }
              ]
            },
            {
              "id": "2a62d057-3604-4c31-b1e6-87ec6ea9977f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/15/29 04:48:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/26 12:26:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/03/27 11:37:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/28/28 07:50:18 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "22e6a526-8a20-4a7a-afe5-50e0ec4cbd65",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 535,
          "address": "578 Fleet Place, Wanamie, Northern Mariana Islands, 4272",
          "acreage": 2048,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "11/09/29 04:19:55 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 90,
          "bidAmount": "$1,238.10",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3796,
            "parcelNumbers": 11696960232032,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "343b7de2-49ff-4b98-91b7-d4b5dfd0b6ca",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/28 01:14:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/24/29 05:13:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/26/29 09:25:00 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/11/28 07:24:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/14/29 05:58:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/14/25 04:34:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/03/26 04:13:47 AM"
                }
              ]
            },
            {
              "id": "f6f6c4c1-a57e-44ac-b21b-5b0ab7083aeb",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/18/26 04:05:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/18/26 11:50:23 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/18/27 03:10:08 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/09/26 12:18:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/18/28 01:57:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/11/28 05:50:12 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/11/26 06:12:47 PM"
                }
              ]
            },
            {
              "id": "07c391e1-c67f-4f5c-96dd-48556f581679",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/27/27 02:10:47 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/09/29 05:52:29 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/24/28 08:02:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/02/26 10:49:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/09/24 12:01:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/08/29 04:08:41 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "4b3c8d45-2ff9-462a-9a4a-b3a13e9db7bb",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 2952,
          "address": "578 Newkirk Avenue, Stewart, Arkansas, 3801",
          "acreage": 3795,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/18/24 03:42:38 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 39,
          "bidAmount": "$1,339.44",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9329,
            "parcelNumbers": 76259272403364,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d5932c1a-70ec-4254-bb8f-9c3bd8c176ab",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/12/29 02:00:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/11/26 09:40:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/07/26 08:52:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/20/26 02:15:47 AM"
                }
              ]
            },
            {
              "id": "2a924350-ebd0-4120-9a8b-d6b161dfa467",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/22/27 02:07:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/22/27 05:02:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/25 03:18:46 AM"
                }
              ]
            },
            {
              "id": "6c9872eb-1015-4eec-af1e-790ebcb98645",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/04/29 08:46:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/10/30 10:15:14 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/03/28 11:48:38 AM"
                }
              ]
            },
            {
              "id": "8d5b8e32-2d44-47ff-8f26-a63108e25b6f",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/13/25 08:03:14 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/19/27 04:45:00 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/23/26 11:48:06 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/20/28 05:25:04 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/07/27 09:26:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/03/25 03:21:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/01/25 12:47:43 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "e10d4099-b352-452f-948c-bfc8050b499e",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3126,
          "address": "791 Congress Street, Bath, South Dakota, 5396",
          "acreage": 7283,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "10/21/27 06:44:27 PM",
          "documents": [],
          "turnTime": 94,
          "bidAmount": "$1,112.85",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1556,
            "parcelNumbers": 81459576533556,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "53052eb1-8d7e-4629-b626-03b318507af7",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/09/28 03:22:07 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/12/29 03:43:31 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/25/29 04:34:38 PM"
                }
              ]
            },
            {
              "id": "e32d4511-e8ad-4484-8516-6e73467e8beb",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/27 02:45:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/10/26 06:36:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/05/27 03:09:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/22/29 02:09:35 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/20/24 06:13:44 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/21/25 05:45:16 PM"
                }
              ]
            },
            {
              "id": "446cbdce-f17f-43c3-9824-6f0b27bca569",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/08/27 02:31:17 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/19/29 08:53:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/16/27 07:41:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/31/26 03:35:13 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f4a4a7d26de514f74",
      "groupName": "Taco Bell Holdings",
      "type": "proposal",
      "data": [
        {
          "id": "c645e588-123f-4246-90df-91ffb3459a8e",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 691,
          "address": "461 Fairview Place, Devon, Palau, 8368",
          "acreage": 6285,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "03/14/26 02:21:13 PM",
          "documents": [],
          "turnTime": 76,
          "bidAmount": "$1,534.56",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3336,
            "parcelNumbers": 99989968174860,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "06188575-c676-422d-ae4d-84497b00dfd7",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/16/27 08:08:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/28 12:38:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/15/27 10:12:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/21/29 06:26:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/06/28 07:52:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/22/29 07:09:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/04/28 07:36:44 PM"
                }
              ]
            },
            {
              "id": "eb3c2eac-9a50-454a-8080-640098b9732d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/06/24 05:32:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/10/25 08:29:54 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/31/28 09:32:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/13/25 02:34:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/13/29 07:05:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/16/25 07:25:49 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/20/29 12:51:20 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "aa9cbc09-5dec-4dfc-b0c7-55d98fdb2171",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 332,
          "address": "632 Locust Street, Freeburn, Colorado, 3976",
          "acreage": 9337,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "02/27/25 07:01:18 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 45,
          "bidAmount": "$3,706.90",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2049,
            "parcelNumbers": 40067059864804,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "10c86035-ad8a-49bf-9813-7270890e7fef",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/08/29 02:13:21 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/16/25 11:16:46 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/11/28 01:27:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/25/27 11:51:28 PM"
                }
              ]
            },
            {
              "id": "9e396f58-1f7f-4f79-b123-878b7b454cf6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/01/29 12:16:49 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/05/25 08:25:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/17/25 06:12:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/28 12:56:10 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/25 01:10:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/04/25 10:18:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/19/26 07:46:09 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "0fffae27-e86d-4222-8492-72174a1d5cb8",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2817,
          "address": "767 Lott Place, Unionville, Kentucky, 6918",
          "acreage": 4965,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/20/28 10:03:12 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 24,
          "bidAmount": "$2,743.80",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4317,
            "parcelNumbers": 17390654402990,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "37ed487c-5dbb-4001-b036-7705747b9b50",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/21/26 02:59:34 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/13/28 12:21:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/18/27 06:34:06 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/28 09:55:45 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/28/27 12:41:12 PM"
                }
              ]
            },
            {
              "id": "2186f255-a461-462b-a86f-cd39aaa9b7fc",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/17/26 03:58:24 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/09/25 11:24:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/15/26 08:10:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/18/26 08:14:28 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "648c6e03-d9ac-4626-9982-304cb139f0b0",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3057,
          "address": "861 Livonia Avenue, Dahlen, Delaware, 4834",
          "acreage": 1807,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "01/04/25 07:55:23 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 93,
          "bidAmount": "$3,595.73",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4445,
            "parcelNumbers": 21002743988331,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "eb42c526-82cd-4ca5-8a6f-203879489a67",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/30/28 10:05:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/22/26 03:21:05 AM"
                }
              ]
            },
            {
              "id": "93f39788-e71e-4d57-83c9-c08263becddf",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/02/25 01:46:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/24/28 10:23:45 AM"
                }
              ]
            },
            {
              "id": "77ea879b-d5df-492b-be21-74fa53e62e93",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/11/28 11:04:16 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/22/29 08:08:48 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/02/29 10:51:44 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/12/26 04:21:37 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "d4a264ef-3c5b-4d98-bd5a-59a16d76f5c6",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3546,
          "address": "268 Turner Place, Hinsdale, Maine, 386",
          "acreage": 2999,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "07/13/29 08:11:49 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 33,
          "bidAmount": "$3,540.76",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1882,
            "parcelNumbers": 44566901501349,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f981cbc4-c67d-4515-9a73-10647428eb4d",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/10/27 03:03:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/27/25 02:41:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/07/29 07:36:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/08/26 03:35:51 AM"
                }
              ]
            },
            {
              "id": "d84bd2db-b7c0-4f2b-81ab-c245b417498f",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/13/24 12:20:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/08/24 12:49:20 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/06/29 03:28:44 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/07/29 07:33:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/06/28 12:33:34 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "3c536445-e240-414a-8701-b30a5e0e46ca",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3341,
          "address": "684 Mermaid Avenue, Wescosville, New Jersey, 665",
          "acreage": 5402,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "07/08/29 10:25:56 AM",
          "documents": [],
          "turnTime": 14,
          "bidAmount": "$1,057.67",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 17,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9914,
            "parcelNumbers": 97929329857789,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "354c9997-7037-48e2-9605-de783d66e972",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/14/28 03:20:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/26 04:34:43 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/25/26 04:36:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/11/27 10:34:13 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/27/28 04:25:57 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/29 10:33:44 AM"
                }
              ]
            },
            {
              "id": "b4ce0ab0-b8ea-43cf-a700-0c500434903a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/08/28 02:36:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/27/29 06:31:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/26/26 05:50:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/14/26 05:05:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/05/25 09:42:45 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/15/27 07:29:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/06/28 03:27:08 PM"
                }
              ]
            },
            {
              "id": "0c97b675-f316-4793-bcf1-f070d32ddad6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/09/29 01:54:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/29 07:09:11 PM"
                }
              ]
            },
            {
              "id": "befe1ebd-6677-4f12-949a-ee44245719a6",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/25 09:07:57 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/06/25 06:06:53 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/06/27 06:28:29 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "6c246403-585e-48d4-9f66-99d14d0d23fc",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3597,
          "address": "229 Vandervoort Avenue, Camptown, Indiana, 8145",
          "acreage": 4682,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "08/25/29 08:51:45 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 88,
          "bidAmount": "$1,650.69",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4744,
            "parcelNumbers": 50866805535902,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1350cd46-d54e-48bd-9970-ff5a3c5cf7ff",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/15/29 11:29:00 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/26/26 11:12:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/14/27 06:17:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/29 12:34:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/31/25 09:23:56 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/12/28 06:49:26 PM"
                }
              ]
            },
            {
              "id": "1df0a291-f73c-4251-84f5-0563851061d4",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/28 02:34:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/11/25 02:07:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/19/27 08:40:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/16/25 09:43:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/27 10:49:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/19/26 12:03:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/17/28 04:54:55 PM"
                }
              ]
            },
            {
              "id": "fdc44b51-b3fd-49b9-a73b-86273993957d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/17/26 01:52:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/29/25 04:50:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/22/26 10:27:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/22/27 10:04:38 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/23/26 07:37:31 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fb7cdb41e19dbd5a0",
      "groupName": "Dominos Deposits",
      "type": "active",
      "data": [
        {
          "id": "4ba34408-77c7-41ac-b7f9-e2a157b25d2a",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1753,
          "address": "930 Underhill Avenue, Cherokee, Minnesota, 5041",
          "acreage": 2677,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/22/24 12:38:02 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 30,
          "bidAmount": "$3,865.32",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3581,
            "parcelNumbers": 61073460539566,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "85f328ac-c904-49ea-89ff-2db886e23a46",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/18/24 09:07:23 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/17/25 12:38:44 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/01/26 01:35:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/17/27 12:01:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/08/25 08:13:42 AM"
                }
              ]
            },
            {
              "id": "f0269ea2-4db4-45d8-b039-7881977d93ec",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/22/29 11:13:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/14/27 11:40:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/06/26 05:54:23 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/26 05:00:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/29/26 03:34:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/04/29 05:27:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/11/27 01:43:22 AM"
                }
              ]
            },
            {
              "id": "00cb1fd3-ba7f-4b7d-b41d-a98d9eaf9bee",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/03/26 12:40:38 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/26 09:38:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/21/28 11:21:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/05/28 03:56:17 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "b00d332b-0f46-4842-a2dc-73cd1e390457",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 736,
          "address": "572 Ferris Street, Lafferty, Hawaii, 2557",
          "acreage": 7208,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "02/26/25 01:39:44 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 12,
          "bidAmount": "$3,865.27",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7704,
            "parcelNumbers": 91059102687350,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "622fd2da-3ed7-4318-965d-d40513fcf5e6",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/17/29 01:04:44 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/16/28 07:26:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/27 06:10:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/15/28 07:06:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/26/28 11:27:03 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/19/28 11:07:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/17/25 06:48:25 AM"
                }
              ]
            },
            {
              "id": "080b9e97-d404-43f2-89a2-8824d0b40a30",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/30/29 06:58:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/16/27 06:57:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/06/26 02:45:35 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "83087c91-a593-42da-92ae-89df5ca01a93",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 1582,
          "address": "747 Juliana Place, Levant, Washington, 9469",
          "acreage": 3988,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "10/01/29 05:59:32 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 65,
          "bidAmount": "$3,099.29",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 7,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8133,
            "parcelNumbers": 72993988418897,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1b928c62-213b-43ed-a88c-df20adab90ce",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/01/30 03:15:28 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/18/25 03:02:17 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/26 02:44:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/17/29 01:17:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/02/25 07:22:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/08/25 12:17:05 PM"
                }
              ]
            },
            {
              "id": "b4b1e038-fc9e-4125-8205-5fefe1420b74",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/20/26 05:08:56 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/26/28 08:41:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/06/25 02:22:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/15/25 03:21:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/14/25 07:50:08 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/17/29 08:14:17 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/19/27 02:51:15 AM"
                }
              ]
            },
            {
              "id": "4a5a6c15-e8b9-4da9-a223-382b33fba928",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/03/27 03:17:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/03/29 03:29:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/21/27 08:28:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/28/29 05:47:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/18/24 04:15:22 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "77bf5c2b-7ebf-49b1-99f9-6fd3982f0bcf",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1607,
          "address": "133 Bridgewater Street, Edmund, Florida, 1310",
          "acreage": 3952,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/26/27 03:46:50 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$3,126.17",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5439,
            "parcelNumbers": 64918036783769,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "221e4760-8c6a-460a-8834-cef5c06d9f6f",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/05/27 11:22:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/27/27 04:25:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/28/25 09:48:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/02/25 01:17:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/21/25 01:32:39 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/15/30 02:10:09 AM"
                }
              ]
            },
            {
              "id": "6776ee1f-e4fe-472e-9663-7899f3404189",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/18/28 06:12:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/09/29 11:57:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/03/27 07:16:38 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/12/28 11:30:13 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/20/28 04:00:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/13/26 06:28:19 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/06/25 02:12:42 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "8ff27e15-426a-44e8-b436-46b59c443c94",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1062,
          "address": "986 Murdock Court, Northridge, North Carolina, 4724",
          "acreage": 9139,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "11/18/26 07:48:31 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 20,
          "bidAmount": "$1,453.91",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3494,
            "parcelNumbers": 40067493698171,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "74602885-51c2-4f58-b735-4f7f9961c9bd",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/19/29 12:26:54 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/16/30 09:15:20 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/05/27 05:31:07 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/27 11:21:04 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/15/27 01:27:53 AM"
                }
              ]
            },
            {
              "id": "f75aae16-e4fb-48b3-a54b-581f488b39fa",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/28/26 03:15:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/26 10:14:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/25/28 04:08:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/27/25 11:13:02 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/16/29 06:58:44 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/12/28 01:07:23 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "ad8b874d-612d-4fe4-9322-fa62fbdfb452",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2263,
          "address": "564 Chauncey Street, Rockhill, North Dakota, 9062",
          "acreage": 9315,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "05/03/26 07:30:17 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 22,
          "bidAmount": "$2,810.14",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 13,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4344,
            "parcelNumbers": 74401002036963,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "ecc92c9e-eab1-4de3-8bf2-f5df90d67770",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/03/26 04:35:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/03/27 05:03:37 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/03/25 07:04:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/10/29 11:50:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/19/26 10:36:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/28/27 12:54:37 PM"
                }
              ]
            },
            {
              "id": "7647bca6-110b-41c0-adbf-61cf85c6f2b4",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/26/27 05:49:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/26 01:39:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/28 08:03:25 PM"
                }
              ]
            },
            {
              "id": "109e644d-d52e-4be6-9f35-64a234441775",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/20/27 10:25:57 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/08/26 07:53:38 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/02/29 09:23:26 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/03/29 08:50:13 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f8a3d76dceea84718",
      "groupName": "Taco Bell Holdings",
      "type": "proposal",
      "data": [
        {
          "id": "bf7d1268-e8ae-497e-8bf0-4dc505b6f8f5",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3537,
          "address": "580 Cumberland Street, Cliff, Vermont, 2525",
          "acreage": 2207,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/21/26 01:44:04 AM",
          "documents": [],
          "turnTime": 98,
          "bidAmount": "$2,396.76",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7893,
            "parcelNumbers": 98868369493974,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2e0900da-6047-4f07-b940-437cd13dd139",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/26 06:00:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/16/26 03:16:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/12/29 10:37:52 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/27/25 02:16:14 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/20/25 09:26:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/26/27 11:09:32 AM"
                }
              ]
            },
            {
              "id": "999544cc-9aa1-434e-bd38-6d04792a0702",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/08/29 06:18:51 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/27/28 12:30:42 AM"
                }
              ]
            },
            {
              "id": "4d9a79a4-9f5d-4be0-885c-835571158197",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/17/27 09:04:13 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/16/27 03:22:59 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/07/27 02:36:30 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "5f9dfc47-95ae-4266-b6fb-07cda0d6a058",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1275,
          "address": "228 McKibben Street, Carrizo, Wyoming, 6319",
          "acreage": 5151,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "11/30/26 02:56:17 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 17,
          "bidAmount": "$2,533.15",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 20,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6479,
            "parcelNumbers": 96824238721413,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "ac4b6452-9989-4bf2-9b13-78535e7cb156",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/02/29 03:10:20 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/26/29 01:14:07 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/04/27 09:58:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/26/28 06:51:19 AM"
                }
              ]
            },
            {
              "id": "bcb302a0-569a-4538-bb32-13080285ebc3",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/15/27 02:54:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/31/29 10:33:42 PM"
                }
              ]
            },
            {
              "id": "ed17fe33-21d3-4a21-b6df-4b3912dc3dc9",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/06/29 09:51:26 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/27/28 08:30:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/15/26 02:03:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/06/27 11:45:33 AM"
                }
              ]
            },
            {
              "id": "5bbb94dd-36d2-4952-95f0-bd4df3d1a0a1",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/30/28 07:15:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/26/26 09:10:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/19/30 03:41:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/30/29 01:51:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/16/27 08:23:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/16/27 02:41:50 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "b80e3138-e85a-4ca1-91fe-bb01867071fe",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2426,
          "address": "821 Meserole Avenue, Wakarusa, Maryland, 5228",
          "acreage": 7843,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "04/20/26 07:19:28 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 49,
          "bidAmount": "$1,369.05",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2160,
            "parcelNumbers": 33778691098411,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "446db5d4-076d-4838-b792-8588f10ffa4e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/06/29 03:58:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/12/29 02:04:44 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/27/25 05:22:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/13/26 01:33:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/30/25 06:42:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/22/24 04:40:30 PM"
                }
              ]
            },
            {
              "id": "6029b0f1-68b9-4dd6-a7ab-c4f2ac86336c",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/08/28 01:26:04 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/25/27 05:37:12 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/09/25 08:42:36 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/27/28 02:28:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/29/28 11:44:43 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "ab5fbc8e-da30-46a1-aca6-eaa1e41600ae",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3272,
          "address": "403 Victor Road, Bodega, Pennsylvania, 7077",
          "acreage": 6091,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/04/26 11:03:01 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 34,
          "bidAmount": "$1,425.97",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9121,
            "parcelNumbers": 55850292940819,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "0510cfec-dc84-4902-815d-a56bd9ead2a8",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/14/25 06:09:31 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/30/27 02:28:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/16/25 08:54:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/14/27 11:02:37 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/13/25 02:49:23 AM"
                }
              ]
            },
            {
              "id": "bade1c0e-7675-42b2-adf7-038f2f3d53c1",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/02/28 04:30:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/16/24 07:31:29 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/12/29 09:17:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/02/29 04:00:27 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/29/29 05:28:07 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "c0e0b7ce-8b64-466f-b87f-9201318bf36f",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 1436,
          "address": "498 Clymer Street, Harborton, New Mexico, 5294",
          "acreage": 7485,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "03/30/25 04:18:53 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 77,
          "bidAmount": "$3,266.71",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5363,
            "parcelNumbers": 95969714182298,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "b97ed6fc-c4db-4397-a51a-4dd69e11de50",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/07/26 06:58:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/25/27 04:05:57 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/28/26 07:24:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/03/26 06:59:58 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/06/26 06:15:32 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/02/28 04:39:11 AM"
                }
              ]
            },
            {
              "id": "94c6712c-82d5-4ca6-865c-0e61c68e51eb",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/02/26 02:40:52 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/25 07:21:25 AM"
                }
              ]
            },
            {
              "id": "034e9e26-422e-4f5c-88fa-99e54b3fd013",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/21/26 10:26:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/09/26 10:37:07 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/29/25 08:50:55 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/14/25 10:59:05 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/30/29 09:34:57 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00fd3751fb4f7c465ca",
      "groupName": "Burger King Coffers",
      "type": "invitation",
      "data": [
        {
          "id": "afaae568-40b2-4846-99ac-e211fe339e40",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 173,
          "address": "202 Chester Court, Belvoir, Arizona, 8104",
          "acreage": 6481,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "08/02/28 04:52:24 AM",
          "documents": [],
          "turnTime": 42,
          "bidAmount": "$3,079.09",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 4,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3086,
            "parcelNumbers": 17653106472672,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e3a3d997-42c1-4eb3-9c9b-c8b5132058df",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/23/29 06:14:34 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/11/26 01:36:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/30/24 03:38:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/14/26 12:59:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/26 09:25:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/13/24 12:32:07 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/16/28 11:41:21 AM"
                }
              ]
            },
            {
              "id": "0620a9b0-3c17-4d03-be9f-8ed6aec1f93d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/26/25 01:08:33 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/14/25 04:42:01 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/25/27 08:24:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/28 04:13:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/06/25 03:33:31 PM"
                }
              ]
            },
            {
              "id": "2ec7b69b-c85d-4ec2-a4a7-f5899d6eb2b2",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/16/26 01:37:35 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/12/29 06:26:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/14/25 08:48:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/27 02:06:58 PM"
                }
              ]
            },
            {
              "id": "30dfea58-94b4-4e85-9383-be021a0c6b44",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/16/27 10:32:28 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/03/27 09:14:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/29/29 02:50:53 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/12/29 12:53:14 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/02/29 04:16:47 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "e0112898-e08c-469a-bfef-ba6aff34e6a5",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 2843,
          "address": "653 Harwood Place, Elwood, Texas, 8112",
          "acreage": 2855,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "06/18/28 05:28:26 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 45,
          "bidAmount": "$2,279.73",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 8,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3344,
            "parcelNumbers": 80605298000273,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "6f4b95b9-a97a-4c07-8afa-15d14ae2fecc",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/13/29 09:52:19 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/29/26 01:07:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/29/29 06:51:39 AM"
                }
              ]
            },
            {
              "id": "fb5e2d35-e2f1-4de2-aed5-175d831038c0",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/10/29 09:25:01 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/02/27 04:28:55 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/27/27 12:57:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/12/27 09:33:02 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/06/29 01:04:41 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "1a4bcd69-d18c-4e3e-944d-c64c2f5c0d73",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 2011,
          "address": "529 Roder Avenue, Summerset, Michigan, 1674",
          "acreage": 4493,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "05/01/26 10:06:08 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 14,
          "bidAmount": "$1,950.33",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5596,
            "parcelNumbers": 39078509053003,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "ab1ea2de-5c27-49a6-80c5-455c64b87042",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/12/26 06:06:11 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/03/29 01:53:25 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/27 11:46:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/14/27 03:42:52 AM"
                }
              ]
            },
            {
              "id": "224edb45-a9b3-4362-afe6-e799b74cc5db",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/28/28 04:49:05 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/01/24 10:39:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/16/26 04:49:49 AM"
                }
              ]
            },
            {
              "id": "f60e7518-258a-421a-9165-2c85c56b6d38",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/24/27 01:16:35 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/04/28 07:47:39 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/09/28 08:00:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/18/25 08:34:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/28 06:56:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/25/29 11:30:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/26/25 12:07:37 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "af181578-9924-43ab-8856-3dd44a51eff1",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 1476,
          "address": "592 Carroll Street, Snowville, New Hampshire, 3662",
          "acreage": 4547,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "01/17/25 09:48:56 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 40,
          "bidAmount": "$2,176.13",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 6,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7721,
            "parcelNumbers": 65100695551881,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "d3054302-609a-457d-90e2-27b22d8f2143",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/07/25 01:18:34 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/04/25 02:03:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/29 04:39:59 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/28/28 03:40:40 AM"
                }
              ]
            },
            {
              "id": "73be6f8a-8acc-4bcf-b81e-85855cdd9d36",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/29/27 11:57:00 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/27 10:37:07 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/12/29 02:28:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/26 05:39:39 AM"
                }
              ]
            },
            {
              "id": "f0f11c00-f1ed-466a-b7bf-d5f6b1347145",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/10/29 03:51:10 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/27 05:42:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/06/27 06:26:23 PM"
                }
              ]
            },
            {
              "id": "97f561e5-ec49-4e86-b004-4fd9a6bcc3b3",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/21/29 11:14:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/11/29 06:09:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/07/29 02:22:42 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "ebb2aedf-488a-43ab-a99b-060155004cf1",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 2808,
          "address": "338 Milton Street, Camino, Mississippi, 1859",
          "acreage": 1481,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "04/09/25 04:14:41 AM",
          "documents": [],
          "turnTime": 6,
          "bidAmount": "$1,422.79",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 7657,
            "parcelNumbers": 63938609216213,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "14f471cb-f056-4f69-8fd8-893a44bf6662",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/03/27 03:47:02 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/26 11:30:40 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/03/28 06:15:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/10/27 11:18:13 AM"
                }
              ]
            },
            {
              "id": "b16bbefe-43a7-4759-939c-c18102353778",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/18/27 09:30:39 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/13/26 05:31:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/24/25 03:09:51 AM"
                }
              ]
            },
            {
              "id": "eaf8823a-0acb-4bd9-827f-289dec39a8a4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/08/27 04:15:30 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/14/27 11:10:32 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/11/27 05:58:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/28/29 07:36:56 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "3e0b087c-43a2-4f31-a366-3cbe8d5fe7e4",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 2846,
          "address": "509 Highland Boulevard, Goldfield, Connecticut, 4991",
          "acreage": 8076,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/20/25 10:17:50 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 57,
          "bidAmount": "$2,881.41",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5248,
            "parcelNumbers": 12163560850315,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "11ecac82-9f4d-407f-ae05-924780ae4fd6",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/31/26 06:32:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/06/25 09:14:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/21/24 05:19:22 AM"
                }
              ]
            },
            {
              "id": "77914814-3e64-49ef-80f6-183b68c3ae8f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/24/26 11:04:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/01/24 06:53:17 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/13/26 05:58:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/11/24 01:06:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/25 07:50:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/29 07:10:31 AM"
                }
              ]
            },
            {
              "id": "b0e5acf7-63b3-4940-ad6a-1eb446b2b199",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/16/28 03:25:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/24/26 01:36:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/05/29 09:52:24 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/02/27 09:34:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/24 11:43:22 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/13/28 01:28:34 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "ba64b314-3895-4570-beec-1d596581d72b",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 2607,
          "address": "951 Lincoln Road, Bentonville, Virgin Islands, 1679",
          "acreage": 5318,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/29/28 08:47:34 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 26,
          "bidAmount": "$3,075.86",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2521,
            "parcelNumbers": 82169165183873,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "57689365-9f9c-42ec-b3d8-a4dcbb7a7851",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/10/28 12:11:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/24/28 10:56:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/25 02:56:37 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/25 06:11:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/30/25 12:35:23 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/01/26 02:52:02 AM"
                }
              ]
            },
            {
              "id": "f8c6b0e2-2257-4f2c-83e9-cae8cb1ba792",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/18/27 05:39:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/03/27 01:32:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/30/26 10:25:18 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/17/28 03:14:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/15/27 06:10:10 PM"
                }
              ]
            },
            {
              "id": "b36b2529-12fc-407a-a846-4a73949df00b",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/19/26 12:01:09 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/22/27 06:58:09 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f607e3409fd171de2",
      "groupName": "Burger King Coffers",
      "type": "invitation",
      "data": [
        {
          "id": "733ea061-2d01-4f98-9876-08fabe5e5dbe",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 546,
          "address": "703 Fiske Place, Calpine, West Virginia, 3732",
          "acreage": 9203,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "05/25/25 12:13:24 AM",
          "documents": [],
          "turnTime": 90,
          "bidAmount": "$1,616.34",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 15,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9691,
            "parcelNumbers": 73583362716120,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "9ad6679c-b7e5-4c87-a44c-5cd88e593bfc",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/26 03:19:34 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/05/26 07:45:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/24/25 06:06:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/16/29 05:31:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/03/26 10:07:48 PM"
                }
              ]
            },
            {
              "id": "b5500a0c-688f-403b-a25d-776b2ef635be",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/23/25 10:17:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/20/25 03:16:17 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/17/28 11:21:39 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "1aaedf22-7cb0-4ce3-97f4-13b1f06cc299",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3741,
          "address": "190 Horace Court, Mappsville, Oregon, 591",
          "acreage": 5876,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "09/15/26 04:44:07 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 42,
          "bidAmount": "$1,762.09",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 12,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 8521,
            "parcelNumbers": 56741813795519,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "63878942-05b9-465f-b3de-86beef26770a",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/27/28 07:58:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/30/26 07:51:08 PM"
                }
              ]
            },
            {
              "id": "0bde51f7-b534-40d6-9502-abe78a181e8c",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/01/27 02:38:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/28/28 10:33:44 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/26/27 06:15:33 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/13/27 10:05:37 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/13/26 10:12:07 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "daaa9424-1fd5-467c-b2ed-ce226750e07c",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 3264,
          "address": "791 Apollo Street, Bawcomville, Utah, 443",
          "acreage": 4951,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "11/05/26 01:14:10 PM",
          "documents": [],
          "turnTime": 86,
          "bidAmount": "$3,563.83",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 5084,
            "parcelNumbers": 10650198291615,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "c3264bd5-9d3f-4fc1-a912-a50ecde4d765",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/31/26 12:58:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/25/29 04:10:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/25 09:43:06 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/12/26 01:13:53 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/23/25 10:36:43 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/25/27 04:27:14 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/05/29 06:21:59 AM"
                }
              ]
            },
            {
              "id": "db86a572-e35c-432c-bdd9-7da4dd7d8568",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/11/25 11:46:34 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/02/29 07:20:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/19/28 09:37:34 PM"
                }
              ]
            },
            {
              "id": "1796085c-c750-42a6-a9a2-e27b5421177f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/21/24 11:57:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/29/25 11:03:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/25/28 06:24:48 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/09/24 01:27:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/07/28 10:01:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/30/29 08:13:29 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "314019fb-470e-41bc-a8bf-30979e8c58b4",
          "status": "proposal",
          "reviewStatus": "submit",
          "site": 3140,
          "address": "559 Bedford Place, Harviell, American Samoa, 341",
          "acreage": 6996,
          "landUse": "industrial",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "10/08/24 05:57:32 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 82,
          "bidAmount": "$1,047.39",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9422,
            "parcelNumbers": 45940724119795,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2ea91f84-16fb-4cb8-b678-83038689f417",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/13/29 03:10:13 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/11/28 07:19:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/20/26 10:23:06 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/29/28 09:35:17 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/23/28 03:04:26 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/06/27 12:59:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/09/26 01:45:38 PM"
                }
              ]
            },
            {
              "id": "77eebb06-71ea-444a-abe0-1d57270c4d3e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/17/28 02:32:53 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/29/28 12:34:56 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "72bed9bd-54e4-4c3c-8059-8caa95c84f29",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1073,
          "address": "912 Seigel Court, Kempton, Nevada, 4242",
          "acreage": 6881,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "12/09/25 01:56:24 PM",
          "documents": [],
          "turnTime": 1,
          "bidAmount": "$1,432.68",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 17,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4078,
            "parcelNumbers": 44884595603388,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "37ca9f05-72cb-48cc-8c07-e29f12fafabd",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/20/26 08:42:35 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/08/29 09:51:54 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/31/26 03:01:52 PM"
                }
              ]
            },
            {
              "id": "617c54b1-a281-4998-92b9-dff4ed4e88f6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/22/28 01:22:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/14/28 03:05:33 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/03/27 10:56:40 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/24/29 07:03:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/14/29 09:43:04 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "feb5b3f8-0614-4b3d-ad6e-bd5308a74bc2",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3664,
          "address": "301 McKibbin Street, Roy, Montana, 1483",
          "acreage": 4004,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "02/18/26 06:03:05 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 41,
          "bidAmount": "$3,364.95",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 9,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 6518,
            "parcelNumbers": 34858332326965,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "36c3b199-179d-4167-ac24-a1cea994c5bd",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/16/25 03:33:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/12/26 01:21:33 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/31/25 06:08:42 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/15/29 12:41:00 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/21/29 11:21:20 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/12/29 07:29:33 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/04/28 05:41:16 AM"
                }
              ]
            },
            {
              "id": "3b533a11-27b2-4329-9bf4-fac2ee7cf39e",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/06/28 05:51:48 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/12/26 03:58:33 AM"
                }
              ]
            },
            {
              "id": "128b9201-0906-417b-a21e-f3c49a2da554",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/21/25 09:32:31 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/08/25 06:41:48 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/27/27 05:11:25 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/28/25 03:21:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/28/27 04:50:46 AM"
                }
              ]
            },
            {
              "id": "4ed434d1-5357-4ead-8bc9-e0693c24cb69",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/01/29 10:28:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/27/27 03:38:31 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/02/27 06:09:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/07/25 02:29:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/02/29 07:41:46 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/28/28 03:17:45 PM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f9ab205f5689a45f8",
      "groupName": "Burger King Coffers",
      "type": "invitation",
      "data": [
        {
          "id": "a7af0256-30b9-4665-84f7-4469d45c8b33",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 676,
          "address": "397 Stuyvesant Avenue, Vincent, California, 8333",
          "acreage": 3205,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "01/04/27 09:54:27 PM",
          "documents": [],
          "turnTime": 58,
          "bidAmount": "$3,784.25",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 4,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9462,
            "parcelNumbers": 89712871353070,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2f915028-37b7-4729-ae91-2d68ae1d6264",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/22/25 12:14:13 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/06/26 08:26:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/11/24 11:38:20 AM"
                }
              ]
            },
            {
              "id": "e51594b8-6b7e-4763-8517-1d34a2b410ab",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/04/24 01:42:58 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/05/27 06:49:56 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/22/26 02:42:06 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/07/28 06:30:12 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/02/28 08:03:48 PM"
                }
              ]
            },
            {
              "id": "fa9c6a7e-90b2-4bcc-8e79-727301c350ce",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/25/28 03:03:48 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/04/26 04:00:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/18/28 11:00:46 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/26/29 08:38:26 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/02/27 10:09:50 AM"
                }
              ]
            },
            {
              "id": "f893afe7-1044-4a54-99b2-e0e0989a709d",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/08/29 11:24:32 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/06/28 02:07:17 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/01/26 11:03:09 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/06/27 07:05:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/18/25 10:33:21 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/28 04:15:11 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "53a7f3bf-574b-4c9e-9b72-56a0e84625ec",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 3478,
          "address": "552 Oxford Street, Edgar, Georgia, 3523",
          "acreage": 7386,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "11/16/28 04:12:30 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 78,
          "bidAmount": "$1,952.67",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2178,
            "parcelNumbers": 44115565167909,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a5a3b58f-a68c-4532-aa12-a46d7c292700",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/16/29 03:06:56 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/12/28 05:13:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/06/25 08:17:24 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/10/29 06:45:01 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/02/29 05:56:03 PM"
                }
              ]
            },
            {
              "id": "dd3ffa09-5052-4c82-ae89-9ae2902b30f7",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/05/28 01:25:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/17/27 10:01:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/12/27 07:40:13 AM"
                }
              ]
            },
            {
              "id": "fedcc0af-3fff-4d8c-ad08-c741ee984fca",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/30/28 10:45:03 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/23/25 01:35:50 AM"
                }
              ]
            },
            {
              "id": "d87c3418-b88b-4c8a-9643-5cb37b80dc1e",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/17/28 05:57:05 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/29/26 08:45:35 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "6d0c1543-d0b4-46a1-9199-e9c31f4b0b4c",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 412,
          "address": "636 Brightwater Court, Warsaw, Virginia, 9459",
          "acreage": 2289,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "04/06/29 02:16:04 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 7,
          "bidAmount": "$2,443.91",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1344,
            "parcelNumbers": 32358885418926,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "a26d2218-72af-4a09-8adc-985509258284",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/06/29 12:19:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/23/24 01:37:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/26/26 02:36:29 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/12/24 03:53:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/07/29 04:26:21 AM"
                }
              ]
            },
            {
              "id": "b11d68ba-fdfa-427e-ac49-32bc37bcc94f",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/28/25 06:59:42 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/09/28 02:34:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/05/28 11:54:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/28/29 10:19:58 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "ad2677f5-f70c-4a4c-a074-9b64667d1027",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1928,
          "address": "638 Clark Street, Ribera, Oklahoma, 8218",
          "acreage": 7481,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "02/25/27 12:55:39 PM",
          "documents": [],
          "turnTime": 71,
          "bidAmount": "$3,447.24",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1983,
            "parcelNumbers": 42665734489223,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "2e00feb2-46c6-4df4-8c56-0d6de2b4884a",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/16/29 06:51:02 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/16/26 05:48:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/02/25 09:42:50 AM"
                }
              ]
            },
            {
              "id": "bab63854-2631-43c5-9327-fd41b9765cd2",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/07/26 02:57:22 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/25 10:55:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/15/26 07:38:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/04/26 05:30:29 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/13/24 01:36:33 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/05/26 03:50:54 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/09/27 05:27:03 AM"
                }
              ]
            },
            {
              "id": "48f3d7e9-06c5-4b4b-a638-23d5cfd04786",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/07/25 04:12:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/01/27 05:27:04 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "5321690f-377f-49e0-a84c-d09c71720e0b",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 379,
          "address": "782 Bragg Street, Denio, Massachusetts, 5518",
          "acreage": 9443,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/03/26 05:53:05 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 78,
          "bidAmount": "$1,571.67",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 17,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1492,
            "parcelNumbers": 92797605828680,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "fc8ffe51-8876-4f0f-973a-ad6c49edc4d2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/19/25 06:38:50 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/02/25 08:30:21 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/25/28 07:39:21 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/16/29 07:56:29 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/24/29 08:03:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/02/28 01:04:18 PM"
                }
              ]
            },
            {
              "id": "781e7c12-a5a2-4ec1-9bc7-e26816af5f94",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/25/30 12:34:18 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/13/25 09:51:34 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/09/28 03:08:32 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/09/27 05:13:28 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/29/25 04:17:01 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/25/25 12:28:12 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/15/26 06:16:27 PM"
                }
              ]
            },
            {
              "id": "731dbc11-1a23-4876-a8e2-af914f3b0226",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/09/29 09:33:22 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/16/26 11:34:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/15/27 01:08:48 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/20/25 05:57:35 AM"
                }
              ]
            }
          ]
        },
        {
          "id": "727b5fc6-e076-48ab-abca-979d54bf9cae",
          "status": "bid-won",
          "reviewStatus": "pending",
          "site": 1342,
          "address": "223 Truxton Street, Broadlands, Alabama, 8000",
          "acreage": 7457,
          "landUse": "industrial",
          "serviceType": "Other Survey",
          "dueDate": "04/08/29 03:38:55 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 60,
          "bidAmount": "$2,409.08",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 5,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 4268,
            "parcelNumbers": 99708621041382,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "0b7646fc-b7b7-479a-bd86-184f3ff823ab",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/25/26 08:02:40 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/02/26 04:20:07 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/19/28 07:47:38 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/01/27 02:00:16 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/21/27 01:48:13 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/06/27 12:41:31 AM"
                }
              ]
            },
            {
              "id": "6d57b515-9748-4d87-bd1d-56443b7f05c2",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/07/24 04:31:52 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/05/25 09:26:43 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/20/27 03:01:41 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/21/27 09:15:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/02/27 11:32:39 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/29/29 01:38:19 AM"
                }
              ]
            },
            {
              "id": "9f4b186e-b38a-4e95-ae90-7528e8817b5b",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/23/28 06:32:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/23/25 03:12:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/14/27 12:41:55 PM"
                }
              ]
            },
            {
              "id": "bade2746-1343-44ce-92dc-599855f69942",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/17/27 10:12:58 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/18/27 07:06:36 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/13/27 11:25:15 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/03/29 04:47:08 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/22/24 10:19:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/27/27 08:03:19 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "427de0d9-2261-43d7-be54-cefad60826cb",
          "status": "proposal",
          "reviewStatus": "pending",
          "site": 3950,
          "address": "320 Cox Place, Nogal, Puerto Rico, 610",
          "acreage": 3214,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "08/17/29 04:25:43 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 94,
          "bidAmount": "$3,545.74",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 3650,
            "parcelNumbers": 44743416686919,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "8e272e62-6740-4898-8286-23caf7b5cc9a",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/14/30 08:50:02 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/15/25 04:59:06 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/21/26 05:22:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/24/27 12:47:51 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/14/29 01:42:40 PM"
                }
              ]
            },
            {
              "id": "b5050d07-6604-4cc8-8a6a-d01402559603",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/04/26 01:27:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/30/27 09:55:29 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/01/25 12:18:20 AM"
                }
              ]
            },
            {
              "id": "9b7bd774-c567-40cd-b607-8f9bb33e3b80",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/14/27 02:37:47 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/01/28 02:38:43 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/23/25 07:09:52 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/16/29 01:20:32 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/14/29 05:10:03 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/09/26 10:38:08 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/08/27 06:22:11 PM"
                }
              ]
            },
            {
              "id": "0e9843d8-9fdd-40cb-99b8-d1bd50a666a4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/07/27 06:53:43 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "02/26/25 06:02:36 AM"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "groupId": "66d0a00f1c04e0a7bb2a2a91",
      "groupName": "Taco Bell Holdings",
      "type": "proposal",
      "data": [
        {
          "id": "a8048d2a-c772-49e0-963d-234d34f65355",
          "status": "lost-bid",
          "reviewStatus": "pending",
          "site": 1484,
          "address": "136 Hamilton Avenue, Nash, Kansas, 836",
          "acreage": 5625,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "04/06/29 08:48:02 PM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 97,
          "bidAmount": "$1,235.24",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 10,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2222,
            "parcelNumbers": 15627020971431,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e04bb735-9461-4f47-aa0c-52ca35ab9ed2",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/10/28 10:18:00 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "09/24/28 06:13:17 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/18/26 11:32:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/12/27 03:13:19 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/25/26 09:12:55 AM"
                }
              ]
            },
            {
              "id": "de816d54-e635-43fe-b61e-6a7acefacaa8",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/06/24 06:43:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/05/28 12:55:29 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/27/27 11:36:38 AM"
                }
              ]
            },
            {
              "id": "a2ef884b-3bd5-4d9e-9cfe-1c2f3f68dabb",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/20/29 10:18:27 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/09/27 01:18:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/16/27 04:24:53 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/23/25 05:29:47 PM"
                }
              ]
            },
            {
              "id": "f3058858-0c66-4bef-bfdb-eca09829946c",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/21/26 08:05:16 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/28/24 07:48:33 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "b1e5db16-57de-429e-8207-f4f1e5831e5d",
          "status": "lost-bid",
          "reviewStatus": "submit",
          "site": 3647,
          "address": "997 Duffield Street, Snelling, Nebraska, 1638",
          "acreage": 6269,
          "landUse": "restaurant",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "05/31/26 02:02:46 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 15,
          "bidAmount": "$1,046.62",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 16,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1396,
            "parcelNumbers": 66847017621737,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "e88e94aa-d6ef-419d-a67a-6a3623a7b871",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "07/31/26 01:10:46 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/21/26 05:44:19 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/13/25 03:43:42 AM"
                }
              ]
            },
            {
              "id": "e47b9114-a044-415c-90cc-cf3d3a07cad7",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/26/28 07:54:21 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/28/29 09:49:58 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/17/28 03:46:32 AM"
                }
              ]
            },
            {
              "id": "96afc770-e572-49c7-a3af-2181e297f633",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/20/26 03:25:51 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/17/26 07:19:08 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/19/29 12:58:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "01/03/28 05:00:42 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/12/27 06:34:22 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "2f876d55-3495-4f74-9315-a52a68af645f",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 1409,
          "address": "433 Essex Street, Spelter, Idaho, 4102",
          "acreage": 4330,
          "landUse": "residential",
          "serviceType": "ALTA/NSPS Land Title Survey",
          "dueDate": "12/18/28 08:43:28 AM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 25,
          "bidAmount": "$1,072.85",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 14,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 2384,
            "parcelNumbers": 75519789518366,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "f8b0fbc8-f18b-4b79-af8f-3244908e9177",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/06/28 04:08:49 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/28/29 10:06:59 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/20/29 01:00:50 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/18/29 08:35:37 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/14/26 07:31:37 AM"
                }
              ]
            },
            {
              "id": "24fc5112-63e7-43b4-9ff6-b932020304d5",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/29 07:24:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/02/25 02:09:50 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "03/23/27 05:25:55 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/21/29 03:42:03 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/27/26 05:48:24 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/26 02:47:58 PM"
                }
              ]
            },
            {
              "id": "c16be4ca-9e0a-45cc-9d75-8224b72fd4e8",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/06/28 03:44:51 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/16/27 11:15:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/25 05:20:16 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/05/29 02:54:44 PM"
                }
              ]
            },
            {
              "id": "ab8b80a7-74f2-48ae-9ff2-70386b6e7dd3",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/13/26 06:23:37 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/31/28 07:50:54 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/26/25 11:40:30 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "08/23/26 10:38:42 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "06/02/25 08:41:16 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "c925647a-6e9e-4288-b074-516cc3179750",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 3623,
          "address": "447 Sullivan Street, Cascades, South Carolina, 5381",
          "acreage": 4120,
          "landUse": "restaurant",
          "serviceType": "Other Survey",
          "dueDate": "10/22/27 12:37:11 AM",
          "documents": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 78,
          "bidAmount": "$3,766.68",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 19,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 1228,
            "parcelNumbers": 73402253759343,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "1958de2b-a08b-47a4-a3f2-12dd4aa470c1",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/03/28 04:45:17 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/01/26 06:32:46 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/11/24 02:08:36 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/11/27 07:30:14 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/14/25 01:06:35 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/23/28 09:35:05 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/03/28 02:59:24 AM"
                }
              ]
            },
            {
              "id": "7aea18ba-67ec-4f3d-b92d-4c872bfd34bc",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/05/27 09:52:36 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/07/28 06:15:24 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "05/12/28 12:03:11 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "01/04/26 01:36:52 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/12/28 12:30:45 PM"
                }
              ]
            },
            {
              "id": "5df2840b-4d08-476b-8e8b-c3bb84338af4",
              "recipient": "Other Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/26/29 11:48:45 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/08/25 03:09:15 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/03/27 10:00:37 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/11/28 01:20:30 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "09/26/28 10:55:05 PM"
                }
              ]
            },
            {
              "id": "7c447b15-e0d9-4537-9a9b-32d3aa94d853",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/15/28 03:13:10 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "03/10/25 07:14:15 PM"
                }
              ]
            }
          ]
        },
        {
          "id": "c1fd1039-3ef6-4bdf-9ae8-fedbaa586598",
          "status": "bid-won",
          "reviewStatus": "submit",
          "site": 2150,
          "address": "235 Visitation Place, Ellerslie, Ohio, 5913",
          "acreage": 9665,
          "landUse": "residential",
          "serviceType": "Other Survey",
          "dueDate": "06/09/28 12:32:39 PM",
          "documents": [
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            }
          ],
          "turnTime": 3,
          "bidAmount": "$1,561.90",
          "projectLink": "https://google.com",
          "localInformation": {
            "orderId": "RAD-3835-POID",
            "proposedTurnTime": 3,
            "image": "https://placehold.co/600x400?text=Placeholder",
            "propertySize": 9365,
            "parcelNumbers": 53723435889038,
            "landUseFull": "Commercial - Miscellaneous Commercial",
            "tableA": "1, 2, 3, 6a, 6b, 7a, 7b1, 7c, 8, 9, 13, 14, 16, 17, 19 ($1,000,000 Liability Insurance).",
            "item20": "Tristique Nulla Aliquet Enim Tortor At Auctor Urna Nunc. nec Ullamcorper Sit Amet Risus Nullam Eget Felis Eget Nunc. Nulla Facilisi Nullam Vehicula Ipsum A."
          },
          "rfpDocuments": [
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document_with_a_longer_title.pdf",
              "link": "https://google.com"
            },
            {
              "title": "RFP_Document.pdf",
              "link": "https://google.com"
            }
          ],
          "orderInformation": {
            "orderDescription": "Here is where the order description goes",
            "specialQuoteRequirements": "Here is where the special quote requirements go",
            "siteLevelRequirements": "Here is where the site level requirements go"
          },
          "conversations": [
            {
              "id": "ef14344d-dc08-4c27-9e76-b12f936fd3c6",
              "recipient": "Bteju Client",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "10/29/28 06:01:47 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/12/25 09:26:40 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "11/03/29 11:10:01 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "11/04/25 11:26:22 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "04/08/25 06:20:36 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "06/24/26 11:29:56 AM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/31/26 11:32:21 AM"
                }
              ]
            },
            {
              "id": "0f297c0d-12f0-4d03-a4c7-bcc28dea0c01",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/30/27 02:15:15 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "08/01/25 07:20:53 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/10/27 01:11:40 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "05/05/25 12:06:11 PM"
                }
              ]
            },
            {
              "id": "d055ed86-a35f-416c-af79-c72d343dc6e2",
              "recipient": "Ranier Dela Cruz",
              "messages": [
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "12/19/25 08:22:23 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/22/29 07:04:15 AM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "10/15/28 08:34:05 PM"
                },
                {
                  "sender": false,
                  "message": "This is a test message",
                  "date": "07/10/27 06:37:37 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "12/27/26 10:05:11 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "04/01/25 03:14:10 PM"
                },
                {
                  "sender": true,
                  "message": "This is a test message",
                  "date": "02/27/28 12:41:46 PM"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}