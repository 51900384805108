import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
  selector: 'info-header',
	imports: [RouterOutlet, CommonModule],
  templateUrl: './info-header.component.html',
	styleUrl: './info-header.component.scss'
})
export class InfoHeader {
	@Input() expanded: boolean = false;
	@Input() title: string = '';
	@Input() type: string = '';

	@Output() toggled = new EventEmitter<boolean>();
	openState: boolean = this.expanded;

	ngOnInit(){
		this.openState = this.expanded;
	}

	signAllATP(){
		alert('signAllATP');
	}

	submitAllBids(){
		alert('submitAllBids');
	}

	projectOverview(){
		alert('projectOverview');
	}

	accordion(){
		this.openState = !this.openState;
		this.toggled.emit(this.openState);
	}
}
