<div class="page-header">
	<a routerLink="/"><img src="../../assets/img/logo.png" alt="logo" /></a>
	<div class="page-header__search">
		<input type="text" class="page-header__search" placeholder="Search" (keyup.enter)="search()" />
		<button class="page-header__search__button" (click)="search()"><span
				class="material-icons">search</span></button>
	</div>
	<div class="page-header__buttons">
		<button class="page-header__buttons__button" (click)="calendar()">
			<div class="page-header__buttons__button__icon"><span class="material-icons">calendar_month</span>
			</div>Calendar
		</button>
		<button class="page-header__buttons__button" (click)="notifications()">
			<div class="page-header__buttons__button__icon"><span class="material-icons">notifications</span></div>
			Notifications
		</button>
		<button class="page-header__buttons__button" (click)="myAccount()">
			<div class="page-header__buttons__button__icon"><span class="material-icons">account_circle</span>
			</div>My Account
		</button>
	</div>
</div>