import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';

import * as tableData from '../../assets/generated.json';

@Component({
	standalone: true,
  selector: 'proposal-details',
	imports: [CommonModule, DocumentsDialog],
  templateUrl: './proposal-details.component.html',
	styleUrl: './proposal-details.component.scss',
})


export class ProposalDetails {
	id: string = '';
	data: any = {};
	projectName: string = '';
	tabState: string = 'rfp';

	activeTab: string = 'rfp-info';
	type: string = '';

	activeBreadcrumb: any = [false, false, false, false, false];

	currentConversation: string = '';

	documentsActive: boolean = false;

  constructor(private route: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {

		// set tab state from url
		this.route.queryParams.subscribe(params => {
			this.activeTab = params['tab'] || 'rfp-info';
		});

		this.route.data.subscribe(data => {
			this.type = data['type'];
		});

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
			for(let group of tableData.groups){
				for(let item of group.data){
					if(item.id === this.id){
						this.data = item;
						this.projectName = group.groupName;
						break;
					}
				}
			}
    });

		// order messages by date
		for(let conversation of this.data.conversations){
			conversation.messages.sort((a: any, b: any) => {
				let res = 0;
				let aDate = new Date(a.date);
				let bDate = new Date(b.date);
				if(aDate < bDate) res = -1;
				if(aDate > bDate) res = 1;
				return res;
			});
		}

		// order conversations by date of last message
		this.data.conversations.sort((a: any, b: any) => {
			let res = 0;
			let aDate = new Date(a.messages[a.messages.length - 1].date);
			let bDate = new Date(b.messages[b.messages.length - 1].date);
			if(aDate < bDate) res = 1;
			if(aDate > bDate) res = -1;
			return res;
		});

		this.currentConversation = this.data.conversations[0].id;

		if(this.type=='invitation'){
			this.activeBreadcrumb[0] = true;
		}
		if(this.type=='proposal'){
			this.activeBreadcrumb[1] = true;
		}
		if(this.type=='active'){
			this.activeBreadcrumb[2] = true;
		}
		if(this.type=='active' && this.data.reviewStatus=='pending'){
			this.activeBreadcrumb[3] = true;
		}
		if(this.type=='active' && this.data.reviewStatus=='complete'){
			this.activeBreadcrumb[4] = true;
		}
  }

	goBack(){
		window.history.back();
	}

	changeConversation(conversation: string){
		this.currentConversation = conversation;
	}

	toggleTab(tab: string){
		this.activeTab = tab;

		// add selected tab to end of url without adding to history
		this.location.replaceState(this.location.path().split('?')[0], `tab=${tab}`);
	}

	toggleDocumentsModal(){
		this.documentsActive = !this.documentsActive;
	}

	sendMessage(){
		alert('send message');
	}

	signATP(){
		alert('signATP');
	}

	submitForReview(){
		alert('submitForReview');
	}

	submitBid(){
		alert('submitBid');
	}

	saveBid(){
		alert('saveBid');
	}

	decline(){
		alert('decline');
	}
}
