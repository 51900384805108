import { Component, inject, model } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserService } from './user.service';
import { FormsModule } from '@angular/forms';
import { User } from './user';
import { SampleUserComponent } from './sample-user/sample-user.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FormsModule, SampleUserComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'connect';
  sampleUser = model<User>({ id: '', name: '' });
  updatedUserName = model<string>('');

  userService: UserService = inject(UserService);

  constructor() {
    this.userService
      .getSampleUser()
      .subscribe((user) => this.sampleUser.set(user));
  }

  saveSampleText() {
    if (this.sampleUser() != null) {
      this.userService
        .saveSampleText(this.updatedUserName(), this.sampleUser())
        .subscribe((updatedUser) => this.sampleUser.set(updatedUser));
    }
  }
}
