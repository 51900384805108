import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  emailRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private appService: AppService,
    private toastr: ToastrService
  ) {}

  protected forgotPassword = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegx),
    ]),
  });

  public password() {
    if (this.forgotPassword.invalid) {
      this.forgotPassword.markAllAsTouched();
      return;
    }
    if (this.forgotPassword.value.email) {
      let result = this.authService
        .forgotPassword(this.forgotPassword.value.email)
        .subscribe((data) => {
          if (data.status === 'SUCCESS') {
            this.appService.setMessage(data.message);
            this.router.navigate(['login']);
          } else {
            this.toastr.error(data.message);
          }
        });
    }
  }
  cancel() {
    this.router.navigate(['login']);
  }
}
